import styled from 'styled-components';

export const TabelaContainer = styled.div`
    width: 100%;
    height: 80%;
    margin-top: 20px;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
`

export const TabelaHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #000;
`

export const TabelaHeaderNome = styled.div`
    width: 40%;
    border-right: 1px solid #000;
    font-size: 40px;
`

export const TabelaHeaderItem = styled.div`
    width: 20%;
    border-right: 1px solid #000;
`

export const TabelaHeaderIncluir = styled.div`
    width: 15%;
`

export const TabelaBody = styled.div`
    display: flex;
    flex-direction: column;
    height: 85%;
    overflow-y: scroll;
`

export const TabelaBodyItem = styled.div`
    width: 20%;
    display: flex;
    justify-content: start;
    border-right: 1px solid #000;
`

export const TabelaBodyItemNome = styled.div`
    width: 40%;
    display: flex;
    justify-content: start;
    border-right: 1px solid #000;
`

export const TabelaBodyItemIncluir = styled.div`
    width: 15%;
    display: flex;
    justify-content: start;
    align-items: center;
`

export const TabelaLinha = styled.div`    
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    padding: 10px;
`



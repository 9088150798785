import { TabelaContainer, TabelaHeader, TabelaHeaderIncluir, TabelaHeaderNome, TabelaHeaderItem, TabelaBody, TabelaLinha, TabelaBodyItem, TabelaBodyItemIncluir,TabelaBodyItemNome } from "./styles"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { setFuncionariosSelecionados } from "../../redux/novoAgendamento/novoAgendamentoActions"
import { setFuncionariosSelecionadosId } from "../../redux/novoAgendamento/novoAgendamentoActions"
import { fetchSetor } from "../../api"


export const TabelaFuncionarios = ({ modo }) => {

    const dispatch = useDispatch();

    
    const { funcionariosSelecionadosId } = useSelector((state) => state.novoAgendamento);
    const { funcionariosSelecionados } = useSelector((state) => state.novoAgendamento);
    const { setoresSelecionados } = useSelector((state) => state.novoAgendamento);

    //checar todos os setores selecionados e consultar o endpoint /funcionarios/setores/:setor
    //para retornar todos os funcionarios daquele setor
    //adicionar todos os funcionarios retornados no array de funcionariosSelecionados
    //usar o useEffect para chamar a função sempre que o array de setoresSelecionados for alterado

    useEffect(() => {
        if (modo === "setor") {
            const fetchFuncionariosPorSetor = async () => {
                try {
                    let novosFuncionariosSelecionados = []; // Variável auxiliar para acumular os funcionários retornados
                    let novosFuncionariosSelecionadosId = []; // Variável auxiliar para acumular os funcionários IDs retornados

                    // checa todos os setores selecionados
                    for (let i = 0; i < setoresSelecionados.length; i++) {
                        // consulta o endpoint /funcionarios/setores/:setor
                        const response = await fetchSetor(setoresSelecionados[i]);
                        console.log(response);
                        response[0].forEach((employee) => {
                            novosFuncionariosSelecionados.push(employee);
                          });
                        
                        
                        // adiciona todos os funcionarios retornados na variável auxiliar
                        
                    }

                    // Atualiza o estado com todos os funcionários acumulados de uma vez
                    dispatch(setFuncionariosSelecionados(novosFuncionariosSelecionados));
                    
                    // Atualiza o estado com todos os funcionários IDs acumulados de uma vez
                    novosFuncionariosSelecionadosId = novosFuncionariosSelecionados.map(funcionario => funcionario.id);

                    dispatch(setFuncionariosSelecionadosId(novosFuncionariosSelecionadosId));
                } catch (error) {
                    console.log(error);
                }
            };
            fetchFuncionariosPorSetor();
        }
    }, [setoresSelecionados, dispatch, modo]);

    console.log(funcionariosSelecionados);
    console.log(funcionariosSelecionadosId);

    return (
        <TabelaContainer>
            <TabelaHeader>
                <TabelaHeaderNome>
                    <h3>Nome</h3>
                </TabelaHeaderNome>
                <TabelaHeaderItem>
                    <h3>Cargo</h3>
                </TabelaHeaderItem>
                <TabelaHeaderItem>
                    <h3>Setor</h3>
                </TabelaHeaderItem>
                <TabelaHeaderIncluir>
                    <h3>Confirmar</h3>
                </TabelaHeaderIncluir>
            </TabelaHeader>
            <TabelaBody>
            {modo === "setor" ? (
                // Renderizar os funcionários selecionados por setor
                funcionariosSelecionados.map(funcionario => (
                    <TabelaLinha key={funcionario.id}>
                        <TabelaBodyItemNome>
                            <h3>{funcionario.name}</h3>
                        </TabelaBodyItemNome>
                        <TabelaBodyItem>
                            <h3>{funcionario.role}</h3>
                        </TabelaBodyItem>
                        <TabelaBodyItem>
                            <h3>{funcionario.department}</h3>
                        </TabelaBodyItem>
                        <TabelaBodyItemIncluir>
                            <input type="checkbox" className="checkbox" defaultChecked={funcionariosSelecionados.includes(funcionario)} onChange={() => {
                                if (funcionariosSelecionados.includes(funcionario)) {
                                    const novosFuncionariosSelecionados = funcionariosSelecionados.filter((f) => f !== funcionario);
                                    dispatch(setFuncionariosSelecionados(novosFuncionariosSelecionados));
                                    const novosFuncionariosSelecionadosId = funcionariosSelecionadosId.filter((f) => f !== funcionario.id);
                                    dispatch(setFuncionariosSelecionadosId(novosFuncionariosSelecionadosId));
                                } else {
                                    dispatch(setFuncionariosSelecionados([...funcionariosSelecionados, funcionario]));
                                    dispatch(setFuncionariosSelecionadosId([...funcionariosSelecionadosId, funcionario.id]));
                                }
                            }}></input>
                        </TabelaBodyItemIncluir>
                    </TabelaLinha>
                )) ) : (
                    // Renderizar os funcionários selecionados por ID
                    funcionariosSelecionados.map(funcionario => (
                        <TabelaLinha key={funcionario.id}>
                            <TabelaBodyItemNome>
                                <h3>{funcionario.name}</h3>
                            </TabelaBodyItemNome>
                            <TabelaBodyItem>
                                <h3>{funcionario.role}</h3>
                            </TabelaBodyItem>
                            <TabelaBodyItem>
                                <h3>{funcionario.department}</h3>
                            </TabelaBodyItem>
                            <TabelaBodyItemIncluir>
                                <input type="checkbox" className="checkbox" defaultChecked={funcionariosSelecionadosId.includes(funcionario.id)} onChange={() => {
                                    if (funcionariosSelecionadosId.includes(funcionario.id)) {
                                        const novosFuncionariosSelecionadosId = funcionariosSelecionadosId.filter((f) => f !== funcionario.id);
                                        dispatch(setFuncionariosSelecionadosId(novosFuncionariosSelecionadosId));
                                    } else {
                                        dispatch(setFuncionariosSelecionadosId([...funcionariosSelecionadosId, funcionario.id]));
                                    }
                                }}></input>
                            </TabelaBodyItemIncluir>
                        </TabelaLinha>
                    ))
                )}
                
                    
            </TabelaBody>
        </TabelaContainer>

    )
}
        

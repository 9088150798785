import styled from 'styled-components';

export const Botao = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 50%;
    width: 15%;
    border-radius: 22px;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    cursor: pointer;
    padding: 40px;
    font-size: 20px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);

    ${props => props.disabled && `
        opacity: 0.5;
        cursor: default;
    `}
`
import { Modal, handleShowModal } from "../../components/Modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchTicketChat, fetchFuncionarioById, fetchTicketById, pushTicket, updateTicketStatus, buscaAudio } from "../../api";
import { useEffect, useRef, useState } from "react";
import { DetalhesContainer, DetalhesFuncionario, DetalhesTicket, DetalhesTicketsData, DetalhesTicketsTitulo, DetalhesTitulo, TicketChatContainer, TicketContainer, TituloContainer, BalaoInteracao, BalaoMensagem, Chat, BoxResposta, RespostaInput, EnviarBotao, ChatTitulo, EncerrarBotao, DetalhesObservacoes, Alerta } from "./styles";
import VoltarIcon from "../../assets/arrow-left-icon.svg"
import expandIcon from '../../assets/expand-icon.svg';

import { useSelector } from "react-redux";

export const Ticket = () => {

  const [funcionarioInfo, setFuncionarioInfo] = useState([]);
  const [ticketInfo, setTicketInfo] = useState([]);
  const [ticketChat, setTicketChat] = useState([]);
  const [resposta, setResposta] = useState('');
  const [ultimaInteracao, setUltimaInteracao] = useState(null);
  const [pollingInterval, setPollingInterval] = useState(null);
  const [ticketAccordion, setTicketAccordion] = useState(false);
  const [funcionarioAccordion, setFuncionarioAccordion] = useState(true);
  const [observacoesAccordion, setObservacoesAccordion] = useState(false);
  const [categoria, setCategoria] = useState('');
  const [observacoes, setObservacoes] = useState('');
  const [observacoesAlert, setObservacoesAlert] = useState(false);

  const categorias = [
    { id: 1, titulo: 'Vale Transporte' },
    { id: 2, titulo: 'Vale Refeição' },
    { id: 3, titulo: 'Uniforme' },
    { id: 4, titulo: 'Horário' },
    { id: 5, titulo: 'Reclamação' },
    { id: 6, titulo: 'Engano' },
    { id: 7, titulo: 'Outros' }
  ];


  const { id } = useParams();
  const chatRef = useRef(null);
  const previousMessageCountRef = useRef(0);

  const handleTicketAccordion = () => {
    setTicketAccordion(!ticketAccordion);
    setFuncionarioAccordion(false);
    setObservacoesAccordion(false);
  }

  const handleFuncionarioAccordion = () => {
    setFuncionarioAccordion(!funcionarioAccordion);
    setTicketAccordion(false);
    setObservacoesAccordion(false);
  }

  const navigate = useNavigate();
  const handleObservacoesAccordion = () => {
    setObservacoesAccordion(!observacoesAccordion);
    setTicketAccordion(false);
    setFuncionarioAccordion(false);
  }

  const fetchTicketChatData = async () => {
    try {
      const ticketChatData = await fetchTicketChat(id);

      setTicketChat(ticketChatData);

      const funcionarioData = await fetchFuncionarioById(ticketChatData.interacoes[0].employeeId);
      setFuncionarioInfo(funcionarioData);

      const ticketData = await fetchTicketById(id);
      console.log(ticketData);
      setTicketInfo(ticketData);

      const ultimaInteracao = ticketChat.interacoes[ticketChat.interacoes.length - 1];
      setUltimaInteracao(ultimaInteracao);

    } catch (error) {
      console.log(error);
    }
  };

  const fetchFuncionarioData = async () => {
    const ticketChatData = await fetchTicketChat(id);
    const funcionarioData = await fetchFuncionarioById(ticketChatData.interacoes[0].employeeId);
    setFuncionarioInfo(funcionarioData);
  };

  const handleEncerrarTicket = () => {

    //   if (categoria === '') {
    //     alert("Selecione uma categoria antes de fechar o ticket");
    //     setObservacoesAccordion(true);
    //     setObservacoesAlert(true);
    //     return;
    // }


    try {

      updateTicketStatus(id, "Concluído");
      
      handleShowModal();

      document.querySelectorAll('#modal button')
        .forEach((button) => {
          button.addEventListener('click', () => {
            document.querySelector('#modal').close();
            navigate(`/tickets/${ticketInfo.queueId}`);
          });
        });

      console.log("Ticket encerrado com sucesso!");
    } catch (error) {
      console.log(error);
      alert("Erro ao encerrar ticket");
    }
  }

  const handleChange = (e) => {
    setResposta(e.target.value);
    console.log(resposta);
  };

  const handleCategoria = (e) => {
    setCategoria(e.target.value);
    console.log(categoria);
  }

  const handleObservacoes = (e) => {
    setObservacoes(e.target.value);
    console.log(observacoes);
  }

  console.log(funcionarioInfo)

  const handleSubmit = async (e) => {
    e.preventDefault();

    const interacao = {
      ticketOwner: funcionarioInfo.id,
      text: resposta,
      status: "ENVIAR",
      file: [],
      userId: 1,
      ticketId: id,
      // interectionId deve ser o id da ultima interacao do ticket em string
      interactionId: ticketChat.interacoes[ticketChat.interacoes.length - 1].id.toString(),
      type: "TICKET",
      // date deve ser a data atual no formato YYYY-MM-DD
      date: new Date().toISOString().slice(0, 10),
    };

    console.log(interacao);

    try {
      await pushTicket(interacao);
      console.log("Ticket atualizado com sucesso!");

      const refreshChat = async () => {
        const ticketChatData = await fetchTicketChat(id);
        setTicketChat(ticketChatData);
        await fetchTicketChatData();
        scrollChatToBottom();
      };

      refreshChat();

    } catch (error) {
      console.log(error);
    } finally {
      setResposta('');
    }
  };


  const startPolling = () => {
    const interval = setInterval(() => {
      fetchTicketChatData();
    }, 5000); // Solicitação a cada 5 segundos (5000 milisegundos)
    console.log(ticketInfo)

    setPollingInterval(interval);
  };

  const stopPolling = () => {
    clearInterval(pollingInterval);
    setPollingInterval(null);
  };

  useEffect(() => {
    fetchFuncionarioData();
    fetchTicketChatData();

    startPolling();

    return () => {
      stopPolling();
    };
  }, []);


  useEffect(() => {
    scrollToBottomIfNeeded();
  }, [ticketChat]);

  useEffect(() => {
    scrollChatToBottom();
  }, [resposta]);

  const scrollToBottomIfNeeded = () => {
    if (!ticketChat.mensagens || !ticketChat.interacoes) {
      return;
    }
    const currentMessageCount = ticketChat.mensagens.length;
    const currentInteractionsCount = ticketChat.interacoes.length;

    if (
      currentMessageCount > previousMessageCountRef.current ||
      currentInteractionsCount > previousMessageCountRef.current
    ) {
      scrollChatToBottom();
    }

    previousMessageCountRef.current = Math.max(
      currentMessageCount,
      currentInteractionsCount
    );
  };

  const scrollChatToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  if (!funcionarioInfo || !ticketChat || !ticketInfo) {
    return null;
  }

  const renderChat = () => {
    if (!ticketChat || !ticketChat.interacoes || !ticketChat.mensagens) {
      return [[], false];
    }

    const { interacoes, mensagens } = ticketChat;

    let isUltimaInteracaoMensagem = false;

    const chatItems = [];

    for (let i = 0; i < interacoes.length; i++) {
      const interacao = interacoes[i];
      chatItems.push(
        <BalaoInteracao key={interacao.id}>
          <p>{interacao.text}</p>
        </BalaoInteracao>
      );

      const mensagem = mensagens[i];
      if (mensagem) {
        chatItems.push(
          <BalaoMensagem key={mensagem.id}>
            <p>{mensagem.text}</p>
          </BalaoMensagem>
        );
        isUltimaInteracaoMensagem = true;
      } else {
        isUltimaInteracaoMensagem = false;
      }

    }
    buscaAudio()
      .then(audio => audio.blob())
      .then(blob => {
        const audioUrl = URL.createObjectURL(blob);
        chatItems.push(`<audio preload="auto" autoplay controls>
                          <source src="${audioUrl}" type="audio/mp3">
                        </audio>`)

      });
    
    return [chatItems, isUltimaInteracaoMensagem];
  };

  const [chatItems, isUltimaInteracaoMensagem] = renderChat();

  const data_formatada = new Date(ticketInfo.date).toLocaleString('pt-BR', {
    timeStyle: 'medium',
    dateStyle: 'medium'
  });


  return (
    <>
      <Modal titulo='Sucesso' mensagem='Ticket encerrado com Sucesso.' botao='OK' />

      <TituloContainer>
        <Link to={`/tickets/${ticketInfo.queueId}`}>
          <img src={VoltarIcon} alt="Voltar" />
        </Link>
        <h1>Ticket</h1>
        {<h1>{ticketInfo.ticketId}</h1>}
      </TituloContainer>

      <TicketContainer>
        <DetalhesContainer>
          <DetalhesTitulo onClick={handleTicketAccordion}>
            <h2>Detalhes do Ticket</h2>
            <img
              src={expandIcon}
              alt="Abrir/Fechar"
              style={{ transform: ticketAccordion ? "rotate(180deg)" : "none" }}
            />
          </DetalhesTitulo>

          {ticketAccordion && <DetalhesTicket expanded={ticketAccordion}>
            <DetalhesTicketsTitulo>
              <h3>Título :</h3>
              <p>{ticketInfo.title}</p>
            </DetalhesTicketsTitulo>
            <DetalhesTicketsData>
              <h3>Data de abertura :</h3>
              <p>{data_formatada}</p>
            </DetalhesTicketsData>
          </DetalhesTicket>}

          <DetalhesTitulo onClick={handleFuncionarioAccordion}>
            <h2>Detalhes do Funcionário</h2>
            <img
              src={expandIcon}
              alt="Abrir/Fechar"
              style={{ transform: funcionarioAccordion ? "rotate(180deg)" : "none" }}
            />
          </DetalhesTitulo>
          {funcionarioAccordion && <DetalhesFuncionario expanded={funcionarioAccordion}>
            <h2>Chapa :</h2>
            <p>{funcionarioInfo.id}</p>
            <h2>Nome :</h2>
            <p>{funcionarioInfo.name}</p>
            <h2>Telefone :</h2>
            <p>{funcionarioInfo.phone || "Sem número"}</p>
            <h2>Departamento :</h2>
            <p>{funcionarioInfo.department || "Sem departamento"}</p>
            <h2>Cargo :</h2>
            <p>{funcionarioInfo.role || "Sem cargo"}</p>
          </DetalhesFuncionario>}
          {/* <DetalhesTitulo onClick={handleObservacoesAccordion}>
                    <h2>Observações</h2>
                    <img
                      src={expandIcon}
                      alt="Abrir/Fechar"
                      style={{ transform: observacoesAccordion ? "rotate(180deg)" : "none" }}
                    />
                </DetalhesTitulo> */}
          {observacoesAccordion && <DetalhesObservacoes expanded={observacoesAccordion}>
            {observacoesAlert && <Alerta>Por favor, escolha uma categoria</Alerta>}
            <h2>Categoria :</h2>
            <select name="categorias" id="categorias" onChange={handleCategoria}>
              <option value="0">Selecione uma opção</option>
              {categorias.map((categoria) => (
                <option key={categoria.id} value={categoria.id}>{categoria.titulo}</option>
              ))}
            </select>
            <h2>Observações :</h2>
            <textarea name="observacoes" id="observacoes" cols="30" rows="10" onChange={handleObservacoes}></textarea>
          </DetalhesObservacoes>
          }
        </DetalhesContainer>

        <TicketChatContainer>
          <ChatTitulo>
            <h2>Chat do Ticket</h2>
            <EncerrarBotao onClick={handleEncerrarTicket}>Fechar Ticket</EncerrarBotao>
          </ChatTitulo>

          <Chat ref={chatRef}>
            {renderChat()}
          </Chat>

          <BoxResposta>
            <RespostaInput
              type="text"
              value={resposta}
              onChange={handleChange}
              placeholder={isUltimaInteracaoMensagem || ticketInfo.status === "CONCLUÍDO" ? "Aguarde o funcionário responder" : "Digite sua mensagem"}
              disabled={isUltimaInteracaoMensagem}
            />
            <EnviarBotao type="submit" onClick={handleSubmit} disabled={isUltimaInteracaoMensagem}>
              Enviar
            </EnviarBotao>
          </BoxResposta>

        </TicketChatContainer>
      </TicketContainer>
    </>
  )
}
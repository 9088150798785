import { Outlet } from "react-router-dom";
import { LayoutContainer } from "./styles";
import Sidebar from "../../components/Sidebar";
import { MainContainer } from "../../components/MainContainer";
import React, { useContext } from 'react';

export function DefaultLayout() {

    
   
    return (
        <LayoutContainer>
            <Sidebar />  
            <MainContainer>
                <Outlet />  
            </MainContainer>   
        </LayoutContainer>
    )
}
import { TabelaContainer, TabelaHeader, TabelaHeaderID, TabelaHeaderNome, TabelaHeaderInfo, TabelaHeaderStatus, TabelaBody, TabelaLinha, StatusBox } from "./styles";
import { Link } from "react-router-dom";
import  GreenDotIcon  from "../../assets/dot-green.svg"
import  RedDotIcon  from "../../assets/dot-red.svg"


export const TabelaTickets = ({tickets, funcionariosInfo}) => {

    const ticketRows = tickets.map((ticket, index) => (
      {
      ticket,
      funcionario: funcionariosInfo[index]
    }))

    return (
        <TabelaContainer>
            <TabelaHeader>
                <TabelaHeaderID>ID</TabelaHeaderID>
                <TabelaHeaderNome>
                    Nome
                </TabelaHeaderNome>
                <TabelaHeaderInfo>
                    Informações
                </TabelaHeaderInfo>
                <TabelaHeaderStatus>
                    Status
                </TabelaHeaderStatus>

            </TabelaHeader>
            <TabelaBody>
              { tickets ? (ticketRows.map((row, index) => (
                <TicketRow key={index} {...row} />
              ))) : <h3>Sem tickets</h3>}
            </TabelaBody>

        </TabelaContainer>
    )
}

const TicketRow = ({ ticket, funcionario }) => {

  if (!ticket || !funcionario) {
    return null;
  }


  // Aqui, você pode extrair todas as informações necessárias de `ticket` e `funcionario`.
  // Por exemplo:
  const ultimaInteracao = ""; // Aqui, você pode extrair a última interação do ticket



  return (
    <Link to={`/ticket/${ticket.id}`}>
      <TabelaLinha>
        <TabelaHeaderID>{ticket.id}</TabelaHeaderID>
        <TabelaHeaderNome>
          <h1>{funcionario.name}</h1>
          <h2>{funcionario.role || "Indefinido"} - {funcionario.branch}</h2>
        </TabelaHeaderNome>
        <TabelaHeaderInfo>
                <h1>{ticket.titulo}</h1>
                <h2>{ultimaInteracao.text}</h2>
              </TabelaHeaderInfo>
              <TabelaHeaderStatus>
                <StatusBox status={ticket.status}>
                  <img src={ticket.status === "EM ABERTO" || ticket.status === "AGUARDANDO RETORNO" || ticket.status === "PENDENTE" ? GreenDotIcon : RedDotIcon } alt="Ponto"/>
                  {ticket.status === "AGUARDANDO RETORNO" ? "AGUARDANDO" : ticket.status}
                  {/* {console.log(ticket.status)} */}
                </StatusBox>
              </TabelaHeaderStatus>
      </TabelaLinha>
    </Link>
  );
};
import { Link } from 'react-router-dom'
import { Botao } from './styles'

export const GrupoCard = ({ icone, texto, onClick, link, disabled}) => {

    if (disabled) {
        return (
            <Botao disabled>
                <img  src={icone} alt={texto}></img>
                {texto}
            </Botao>
        )
    }
    
    return (
        <Link to={link}>
            <Botao onClick={onClick}>
                <img src={icone} alt={texto}></img>
                {texto}
            </Botao>
        </Link>
    )
}
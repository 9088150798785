import styled from 'styled-components';

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    height: 100%;
    gap: 10px;
    margin-top: 16px;

    textarea {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 22px;
      padding-left: 10px;
      font-size: 18px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
`;
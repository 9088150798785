import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 75vw;
    overflow: hidden;
    min-height: 100vh;
`
export const FiltroContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 0 10%;
    width: 100%;
    height: 10%;
    margin-top: 5%;
`
export const FiltroTitulo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 24px;
        color: #5F6368;
    }

`
export const FiltroOpcoes = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: #F5F5F5;
    font-size: 24px;
    color: #313A46;
    cursor: pointer;
    z-index: 1;
    width: 300px;
`
export const FiltroOpcao = styled.div`
    h2 {
    font-size: 18px;
    color: #313A46;
    padding: 10px;
}
`
export const TabelaLinha = styled.div`    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #000;
    padding: 10px;
    cursor: pointer;
    
    &:hover {
        background: #F1F3F4;
    }
`

export const AvisoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F1F3F4;
    width: 100%;
    height: 100%;
    padding: 10% 0;
    text-align: center;
    color: #5F6368;


    h1 {
        font-size: 48px;
        font-weight: 400;
        padding-bottom: 36px;
    }

    h2 {
        font-size: 24px;
        font-weight: 400;
    }

    button {
        background: #ED2C6D;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-size: 24px;
        padding: 10px 20px;
        margin-top: 48px;
        cursor: pointer;
    }
`


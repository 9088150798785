import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75vw;
    overflow: hidden;
`

export const BotaoContainer = styled.div`
    display: flex;
    align-items: end;
    justify-content: end;
    width: 100%;
`

export const BotaoAcao = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #ED2C6D;
    color: white;
    border-radius: 24px;
    border: none;
    font-size: 18px;
    min-width: 150px;
    cursor: pointer;

    &:hover {
        background-color: #D91F60;
    }
`

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
`

export const UsuarioCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 300px;
    margin: 10px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 24px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        background-color: #F4F4F4;

        img {
            border: 2px solid #f4f4f4;
        }

        p {
            color: #ED2C6D;
        }
    }
`

export const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: 10px;
    }
`

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    flex: 1;

    p {
        margin: 10px;
    }
`

export const JanelaStyle = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 40vw;
    height: 100vh;
    overflow: hidden;
    border: 1px solid black;
    top: 0;
    right: 0;
    position: fixed;
    background-color: #f4f4f4;
`

export const IconesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    height: 100%;
    width: 100%;

    img {
        justify-self: flex-start;
        align-self: flex-end;
        cursor: pointer;
    }
    
`

export const JanelaHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-height: 120px;
    flex-grow: 1;
    padding: 24px 24px 0 24px;
    
    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
    }

    h2 {
        font-size: 24px;
        margin-left: 10px;
    }

    p {
        font-size: 18px;
        margin-left: 10px;
    }
`

export const AcoesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    padding: 0 24px 0 24px;
`

export const Acao = styled.div`
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;

    &:hover {
        background-color: #F4F4F4;
    }

    img {
        width: 30px;
        height: 30px;
        margin-left: 20px;
        margin-right: 10px;
    }

    p {
        font-size: 16px;
        font-weight: 500;
    }
`

export const PermissoesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0 24px 0 24px;
    margin-top: 20px;

    h3 {
        font-size: 18px;
        font-weight: 500;
    }
`

export const Permissoes = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    margin-left: 10px;
`

export const Permissao = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    p {
        font-size: 16px;
        margin-left: 10px;
    }

    input {
        margin: 5px 10px 5px 10px;
        width: 20px;
        height: 20px;
    }
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;

    label {
        font-size: 18px;
        font-weight: 500;
    }

    input {
        width: 100%;
        height: 40px;
        border-radius: 24px;
        border: 1px solid black;
        margin-top: 10px;
        padding: 10px;
        font-size: 16px;
    }
`

export const BotaoSalvar = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #ED2C6D;
    color: white;
    border-radius: 24px;
    border: none;
    font-size: 18px;
    min-width: 150px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        background-color: #D91F60;
    }
`

export const FullInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0 24px 0 24px;
    margin-top: 20px;

    h3 {
        font-size: 18px;
        font-weight: 500;
    }

    p {
        font-size: 16px;
        margin-left: 10px;
        margin-top: 4px;
    }
`

export const Col1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    padding: 10px;
    margin-left: 10px;

    h4 {
        font-size: 16px;
        font-weight: 500;
    }

    input {
        width: 100%;
        height: 40px;
        border-radius: 12px;
        border: 1px solid black;
        margin-top: 10px;
        padding: 10px;
        font-size: 16px;
        margin-bottom: 10px;
    }
`

export const BotaoContainerFim = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 0 24px 0 24px;
    margin-top: 20px;
`
import { Link } from "react-router-dom";
import { TabelaContainer, TabelaHeader, TabelaHeaderNome, TabelaHeaderInfo, TabelaHeaderStatus, TabelaBody, TabelaLinha, StatusBox, TabelaBodyItemNome, TabelaBodyInfo} from "./styles";
import GreenDotIcon from "../../assets/dot-green.svg";
import RedDotIcon from "../../assets/dot-red.svg";
import YellowDotIcon from "../../assets/dot-yellow.svg";

export const TabelaCandidatos = ({candidatos}) => {


    console.log(candidatos);
    const calcularIdade = (dataNascimento) => {
        const dataAtual = new Date();
        const dataNascimentoFormatada = new Date(dataNascimento);
        let idade = dataAtual.getFullYear() - dataNascimentoFormatada.getFullYear();
        const mes = dataAtual.getMonth() - dataNascimentoFormatada.getMonth();

        if (mes < 0 || (mes === 0 && dataAtual.getDate() < dataNascimentoFormatada.getDate())) {
            idade--;
        }

        return idade;
    }

    const statusColors = {
        "EM ANALISE": YellowDotIcon,
        "APROVADO": GreenDotIcon,
        "REPROVADO": RedDotIcon,
        "NOVO": YellowDotIcon,
    };

    const CandidatoRow = ({ candidato }) => {
        if (!candidato) {
            return null;
        }

        const idadeCandidato = calcularIdade(candidato.candidato.dataNascimento);

        return (
            <Link to={`/candidato/${candidato.candidato.id}`}>
                <TabelaLinha>
                    <TabelaBodyItemNome>
                        <h1>{candidato.candidato.nomeCompleto}</h1>
                    </TabelaBodyItemNome>
                    <TabelaBodyInfo>
                        <h2>{idadeCandidato} anos</h2>
                        <h3>{candidato.endereco.cidade}</h3>
                    </TabelaBodyInfo>
                    <TabelaHeaderStatus>
                        <StatusBox status={candidato.candidato.situacao}>
                            <img
                                src={statusColors[candidato.candidato.situacao]}
                                alt="Ponto"
                            />
                            {candidato.candidato.situacao}
                        </StatusBox>
                    </TabelaHeaderStatus>
                </TabelaLinha>
            </Link>
        );
    };


    return (
        <TabelaContainer>
            <TabelaHeader>
                <TabelaHeaderNome>
                    Nome
                </TabelaHeaderNome>
                <TabelaHeaderInfo>
                    Informações
                </TabelaHeaderInfo>
                <TabelaHeaderStatus>
                    Status
                </TabelaHeaderStatus>
            </TabelaHeader>
            <TabelaBody>
                {candidatos.map((candidato) => (
                    <CandidatoRow key={candidato.id} candidato={candidato} />
                ))}
            </TabelaBody>

        </TabelaContainer>
    )
}
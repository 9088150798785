import { Titulo } from "../../components/Titulo";
import { TabelaHistoricoUploads } from "../../components/TabelaHistoricoUploads";


export const Uploads = () => {

    const historico = [
        {
            id: 1,
            data: "01/03/2024",
            tipo: "Informe de Rendimentos",
            nomeArquivo: "InformeRendimentos2023.pdf"
        },{
            id: 2,
            data: "05/03/2024",
            tipo: "Holetite",
            nomeArquivo: "Holerite-03-2024.pdf"
        },{
            id: 3,
            data: "05/04/2024",
            tipo: "Holerite",
            nomeArquivo: "Holerite-04-2024.pdf"
        },{
            id: 4,
            data: "05/05/2024",
            tipo: "Holerite",
            nomeArquivo: "Holerite-05-2024.pdf"
        },{
            id: 5,
            data: "05/06/2024",
            tipo: "Holerite",
            nomeArquivo: "Holerite-06-2024.pdf"
        }
    ];

    return (
        <>
            <Titulo titulo="Uploads" />
            <p>Histórico de uploads</p>
            
            <TabelaHistoricoUploads arrayHistorico={historico} />
            
            <form>
                <select>
                    <option selected>Holerite</option>
                    <option>Holerite 13°</option>
                    <option>Informe de Rendimentos</option>
                </select>
                <input type="file" accept=".pdf" />
                <button>Upload</button>
            </form>
        </>
    )
};
import { Titulo } from './styles.js';
import video from '../../assets/Encerramento de tickets SGT.mp4';

export default function NovaHome() {
    return (
        <>
            <div>
                <Titulo>Sistema Gerenciador de Tickets</Titulo>
                <h2>(SGT)</h2>
            </div>
            <div>
                <h3>Seja bem-vindo ao SGT</h3>
                <p>Este é um sistema para gerenciamento de tickets de suporte técnico.</p>
                <p>Para acessar o sistema, clique no menu "Login" no canto superior direito.</p>
            </div>
            <div>
                <video width="640" height="480" controls autoPlay loop autoFocus>
                    <source src={video} type="video/mp4" />
                    Seu navegador não suporta a tag de vídeo.
                </video>
            </div>
        </>
    );
};
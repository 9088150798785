import { Titulo } from "../../components/Titulo";
import { Container, FiltroContainer, FiltroTitulo, FiltroOpcoes, FiltroOpcao, CheckboxFilial, CheckboxFilialContainer } from "./styles";
import expandIcon from '../../assets/expand-icon.svg';
import { useState, useEffect, useRef } from "react";
import { TabelaTickets } from "../../components/TabelaTickets";
import { useDispatch, useSelector } from "react-redux";
import { fetchTickets, fetchTicketsByFilaId } from "../../api";
import { setTickets } from "../../redux/tickets/ticketsActions";
import { fetchFuncionarioById } from "../../api";
import { fetchTicketInteraction } from "../../api";


import { useNavigate, useParams } from "react-router-dom";

export const Tickets = () => {

  const idObject = useParams();
  const id = idObject.id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const grupo = useSelector((state) => state.auth.grupoAcesso);

  if (idObject === "redirect") {
    navigate(`/tickets/${grupo}/`);
    // console.log(grupo);
  }


  const filtros = [
    {
      id: 1,
      titulo: "Tickets Abertos"
    },
    {
      id: 2,
      titulo: "Tickets Fechados"
    },
    {
      id: 3,
      titulo: "Todos os Tickets"
    }
  ]

  const [filiais, setFiliais] = useState([]);
  const [filiaisSelecionadas, setFiliaisSelecionadas] = useState([]);
  const [filtro, setFiltro] = useState(filtros[0]);
  const [filtroAtivo, setFiltroAtivo] = useState(false);
  const [ticketsFiltrados, setTicketsFiltrados] = useState([]);
  const [funcionariosInfo, setFuncionariosInfo] = useState([]);
  const [todosTickets, setTodosTickets] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]);
  // const [ultimasInteracoes, setUltimasInteracoes] = useState([]);

  const tickets = useSelector((state) => state.tickets.tickets);



  useEffect(() => {
    const uniqueFuncionarioIds = [...new Set(tickets.map(ticket => ticket.idDonoTicket))];
    Promise.all(uniqueFuncionarioIds.map(id => fetchFuncionarioById(id)))
      .then(funcionariosInfo => {
        const funcionariosMap = {};
        uniqueFuncionarioIds.forEach((id, index) => {
          funcionariosMap[id] = funcionariosInfo[index];
        });


        setFuncionarios(funcionariosMap);

        //setFiliais
        Promise.all(Object.values(funcionariosInfo).map(funcionario => funcionario.branch))
          .then(empresasFiliais => {

            // REMOVE undefined from empresasFiliais
            empresasFiliais = empresasFiliais.filter(filial => filial !== undefined);
            setFiliais([...new Set(empresasFiliais)]);

          });

      });

  }, [tickets]);

  useEffect(() => {
    fetchTicketsByFilaId(id)
      .then((tickets) => {
        // console.log(tickets);

        dispatch(setTickets(tickets));
      }
      );
  }, [dispatch, id]);

  // useEffect(() => {
  //   const getUltimasInteracoes = tickets.map(ticket => {
  //     // get last interacao from interacoes
  //     // to get interacoes, we need to fetch historico from ticket in fetchTicketInteraction(ticket.id)

  //     fetchTicketInteraction(ticket.id).then((interacoes) => {
  //       const lastInteracao = interacoes[interacoes.length - 1];
  //       setUltimasInteracoes([...ultimasInteracoes, lastInteracao]);
  //     });
  //     return ultimasInteracoes;
  //   });
  // }, [tickets]);

  useEffect(() => {
    setFiliaisSelecionadas(filiais);
    console.log(filiais);
  }, [filiais]);

  const filtraTickets = () => {
    let ticketsFiltradosPorStatus;
    switch (filtro.id) {
      case 1:
        ticketsFiltradosPorStatus = tickets.filter(
          (ticket) => ticket.status === 'EM ABERTO' || ticket.status === 'AGUARDANDO RETORNO' || ticket.status === 'PENDENTE'
        );
        break;
      case 2:
        ticketsFiltradosPorStatus = tickets.filter((ticket) => ticket.status === 'CONCLUÍDO');
        break;
      case 3:
        ticketsFiltradosPorStatus = tickets;
        break;
      default:
        ticketsFiltradosPorStatus = [];
        break;
    }

    const ticketsFiltradosPorFilial = ticketsFiltradosPorStatus.filter(ticket => filiaisSelecionadas.includes(funcionarios[ticket.idDonoTicket]?.branch));
    setTicketsFiltrados(ticketsFiltradosPorFilial);
  };

  useEffect(() => {
    filtraTickets();
  }, [filiaisSelecionadas, filtro, todosTickets, funcionarios]);;

  useEffect(() => {
    const novosFuncionariosInfo = ticketsFiltrados.map(ticket => funcionarios[ticket.idDonoTicket]);
    setFuncionariosInfo(novosFuncionariosInfo);
  }, [ticketsFiltrados, funcionarios]);

  const handleClick = () => {
    setFiltroAtivo(!filtroAtivo);

  }

  const handleFiltro = (id) => {
    setFiltro(filtros.find(filtro => filtro.id === id));
    setFiltroAtivo(false);
  }

  const handleCheckboxChange = (filial) => {
    if (filiaisSelecionadas.includes(filial)) {
      setFiliaisSelecionadas(filiaisSelecionadas.filter(f => f !== filial));
    } else {
      setFiliaisSelecionadas([...filiaisSelecionadas, filial]);
    }
  };


  // would like to take ticketsFiltrados and filter by filiaisRadial

  return (
    <>
      <Container>
        <Titulo titulo='Tickets' />

        <FiltroContainer>

          <CheckboxFilialContainer>
            {filiais.map((filial, index) => (
              // Renderizar um checkbox para cada filial if filial === "" don't render
              filial !== "" &&
              <CheckboxFilial key={index}>
                <input
                  type="checkbox"
                  id={filial}
                  name={filial}
                  value={filial}
                  checked={filiaisSelecionadas.includes(filial)} // Verificar se a filial está selecionada
                  onChange={() => handleCheckboxChange(filial)} // Passar a filial para o manipulador
                />
                <label htmlFor={filial}>{filial}</label>
              </CheckboxFilial>
            ))}
          </CheckboxFilialContainer>
          <div onClick={() => handleClick()}>

            <FiltroTitulo>
              <h2>{filtro.titulo}</h2>
              <img src={expandIcon} alt='expand-icon' />
            </FiltroTitulo>

            {filtroAtivo && (

              <FiltroOpcoes>
                {filtros.map((filtro) => (
                  <FiltroOpcao key={filtro.id} id={filtro.id} onClick={(e) => handleFiltro(filtro.id)}>
                    <h2>{filtro.titulo}</h2>

                  </FiltroOpcao>
                ))}
              </FiltroOpcoes>
            )}

          </div>

        </FiltroContainer>

        <TabelaTickets tickets={ticketsFiltrados} funcionariosInfo={funcionariosInfo} />

      </Container>
    </>
  );
};
import { Link } from "react-router-dom";
import { LinhaRosa, TituloContainer } from "./styles";
import VoltarIcon from "../../assets/arrow-left-icon.svg";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { fetchCandidatoById } from "../../api";
import { CurriculoContainer, CurriculoHeader, CurriculoHeaderInfo } from "./styles";
import { DadosCandidatosContainer, DadosCandidatosInfo, DadosCandidatosInfoContainer, DadosCandidatosTitulo, VoltarContainer, BotoesContainer } from "./styles";
import { LinhaCinza } from "./styles";
import Botao from "../../components/Botao";
import { patchSituacaoCandidato } from "../../api";
import React from "react";

export const Candidato = () => {

        const { id } = useParams();

        const [candidatoInfo, setCandidatoInfo] = useState(null);

        const fetchCandidatoData = async () => {
            try {
                const candidatoData = await fetchCandidatoById(id);
                console.log("Candidato Data:", candidatoData);
                setCandidatoInfo(candidatoData);
            } catch (error) {
                console.log("Error fetching candidato data:", error);
            }
        }

        useEffect(() => {
            fetchCandidatoData();
        }
        , []);

        const calcularIdade = (dataNascimento) => {
            const dataAtual = new Date();
            const dataNascimentoFormatada = new Date(dataNascimento);
            let idade = dataAtual.getFullYear() - dataNascimentoFormatada.getFullYear();
            const mes = dataAtual.getMonth() - dataNascimentoFormatada.getMonth();

            if (mes < 0 || (mes === 0 && dataAtual.getDate() < dataNascimentoFormatada.getDate())) {
                idade--;
            }

            return idade;
        }

        console.log(candidatoInfo)

        if (!candidatoInfo) {
            return null;
        }

        const formatString = () => {
            // Função auxiliar para preencher campos com espaços à direita para alcançar o tamanho especificado
            const fillWithSpaces = (text, length) => {
                if (!text) text = ""; // Tratamento para valores indefinidos ou nulos
                const spacesToAdd = length - text.length;
                if (spacesToAdd <= 0) return text;
                return text + " ".repeat(spacesToAdd);
              };
          
            let superstring = "";
          
            // DADOS PESSOAIS
            const dadosPessoais = candidatoInfo.candidato;
            const temCNH = candidatoInfo.documento.some(documento => documento.nomeDocumento === 'CNH') ? 'S' : 'N';
            const numeroCNH = candidatoInfo.documento.some(documento => documento.nomeDocumento === 'CNH') ? candidatoInfo.documento.find(documento => documento.nomeDocumento === 'CNH').numero : '';
            const cpf = candidatoInfo.documento.some(documento => documento.nomeDocumento === 'CPF') ? candidatoInfo.documento.find(documento => documento.nomeDocumento === 'CPF').numero : '';
            const rg = candidatoInfo.documento.some(documento => documento.nomeDocumento === 'RG') ? candidatoInfo.documento.find(documento => documento.nomeDocumento === 'RG').numero : '';
            const experienciaProfissional1 = candidatoInfo.experienciaProfissional[0] || {};
            const experienciaProfissional2 = candidatoInfo.experienciaProfissional[1] || {};
            const experienciaProfissional3 = candidatoInfo.experienciaProfissional[2] || {};

            superstring +=
                fillWithSpaces(dadosPessoais.nomeCompleto, 30) +          // Nome completo
                fillWithSpaces(candidatoInfo.endereco.rua, 30) +          // Rua
                fillWithSpaces(candidatoInfo.endereco.numero, 5) +        // Número
                fillWithSpaces(candidatoInfo.endereco.cidade, 20) +       // Cidade
                fillWithSpaces(candidatoInfo.endereco.bairro, 20) +       // Bairro
                fillWithSpaces(dadosPessoais.telefone, 15) +              // Telefone
                fillWithSpaces(dadosPessoais.celular, 15) +               // Celular
                fillWithSpaces(dadosPessoais.email, 80) +                 // Email
                fillWithSpaces(dadosPessoais.dataNascimento.replace(/-/g, ""), 8) + // Formatando a data de nascimento para "DDMMYYYY"
                fillWithSpaces(dadosPessoais.estadoCivil, 20) +           // Estado civil
                fillWithSpaces(temCNH, 1) +                               // Habilitado
                fillWithSpaces(numeroCNH, 15) +                           // Registro
                fillWithSpaces('', 4) +                                   // Categoria          
                fillWithSpaces(cpf, 15) +                                 // Cpf
                fillWithSpaces(rg, 15) +                                  // Rg
                fillWithSpaces('', 35) +                                  // Cargo pretendido
                fillWithSpaces('', 50) +                                  // Nivel Hierarquico
                fillWithSpaces('', 100) +                                 // Area de atuação
                fillWithSpaces('', 20) +                                  // Grau de Instrucao
                fillWithSpaces('', 100) +                                 // Tecnico em e data
                fillWithSpaces('', 100) +                                 // Superior em e instituicao
                ( experienciaProfissional1.nomeEmpresa ? fillWithSpaces(experienciaProfissional1.nomeEmpresa, 150) : fillWithSpaces('', 150) ) +                // Empresa
                ( experienciaProfissional1.cargo ? fillWithSpaces(experienciaProfissional1.cargo, 100) : fillWithSpaces('', 100) ) +                            // Cargo
                ( experienciaProfissional1.dataAdmissao ? fillWithSpaces(experienciaProfissional1.dataAdmissao.split('-')[2], 2) : fillWithSpaces('', 2) ) +    // Admissao DIA
                ( experienciaProfissional1.dataAdmissao ? fillWithSpaces(experienciaProfissional1.dataAdmissao.split('-')[1], 2) : fillWithSpaces('', 2) ) +    // Admissao MES
                ( experienciaProfissional1.dataAdmissao ? fillWithSpaces(experienciaProfissional1.dataAdmissao.split('-')[0], 4) : fillWithSpaces('', 4) ) +    // Admissao ANO
                ( experienciaProfissional1.dataDemissoa ? fillWithSpaces(experienciaProfissional1.dataDemissoa.split('-')[2], 2) : fillWithSpaces('', 2) ) +    // Demissao DIA
                ( experienciaProfissional1.dataDemissoa ? fillWithSpaces(experienciaProfissional1.dataDemissoa.split('-')[1], 2) : fillWithSpaces('', 2) ) +    // Demissao MES
                ( experienciaProfissional1.dataDemissoa ? fillWithSpaces(experienciaProfissional1.dataDemissoa.split('-')[0], 4) : fillWithSpaces('', 4) ) +    // Demissao ANO
                ( experienciaProfissional2.nomeEmpresa ? fillWithSpaces(experienciaProfissional2.nomeEmpresa, 150) : fillWithSpaces('', 150) ) +                // Empresa
                ( experienciaProfissional2.cargo ? fillWithSpaces(experienciaProfissional2.cargo, 100) : fillWithSpaces('', 100) ) +                            // Cargo
                ( experienciaProfissional2.dataAdmissao ? fillWithSpaces(experienciaProfissional2.dataAdmissao.split('-')[2], 2) : fillWithSpaces('', 2) ) +    // Admissao DIA
                ( experienciaProfissional2.dataAdmissao ? fillWithSpaces(experienciaProfissional2.dataAdmissao.split('-')[1], 2) : fillWithSpaces('', 2) ) +    // Admissao MES
                ( experienciaProfissional2.dataAdmissao ? fillWithSpaces(experienciaProfissional2.dataAdmissao.split('-')[0], 4) : fillWithSpaces('', 4) ) +    // Admissao ANO
                ( experienciaProfissional2.dataDemissoa ? fillWithSpaces(experienciaProfissional2.dataDemissoa.split('-')[2], 2) : fillWithSpaces('', 2) ) +    // Demissao DIA
                ( experienciaProfissional2.dataDemissoa ? fillWithSpaces(experienciaProfissional2.dataDemissoa.split('-')[1], 2) : fillWithSpaces('', 2) ) +    // Demissao MES
                ( experienciaProfissional2.dataDemissoa ? fillWithSpaces(experienciaProfissional2.dataDemissoa.split('-')[0], 4) : fillWithSpaces('', 4) ) +    // Demissao ANO
                ( experienciaProfissional3.nomeEmpresa ? fillWithSpaces(experienciaProfissional3.nomeEmpresa, 150) : fillWithSpaces('', 150) ) +                // Empresa
                ( experienciaProfissional3.cargo ? fillWithSpaces(experienciaProfissional3.cargo, 100) : fillWithSpaces('', 100) ) +                            // Cargo
                ( experienciaProfissional3.dataAdmissao ? fillWithSpaces(experienciaProfissional3.dataAdmissao.split('-')[2], 2) : fillWithSpaces('', 2) ) +    // Admissao DIA
                ( experienciaProfissional3.dataAdmissao ? fillWithSpaces(experienciaProfissional3.dataAdmissao.split('-')[1], 2) : fillWithSpaces('', 2) ) +    // Admissao MES
                ( experienciaProfissional3.dataAdmissao ? fillWithSpaces(experienciaProfissional3.dataAdmissao.split('-')[0], 4) : fillWithSpaces('', 4) ) +    // Admissao ANO
                ( experienciaProfissional3.dataDemissoa ? fillWithSpaces(experienciaProfissional3.dataDemissoa.split('-')[2], 2) : fillWithSpaces('', 2) ) +    // Demissao DIA
                ( experienciaProfissional3.dataDemissoa ? fillWithSpaces(experienciaProfissional3.dataDemissoa.split('-')[1], 2) : fillWithSpaces('', 2) ) +    // Demissao MES
                ( experienciaProfissional3.dataDemissoa ? fillWithSpaces(experienciaProfissional3.dataDemissoa.split('-')[0], 4) : fillWithSpaces('', 4) ) +    // Demissao ANO
                fillWithSpaces('', 1000) +                                // Curso
                fillWithSpaces('', 100)                                   // Observacoes

          
            // Preencher com espaços vazios para completar os 3496 caracteres
            if (superstring.length < 3496) {
              superstring += " ".repeat(3496 - superstring.length);
            }
            
            console.log("Superstring gerada:", superstring);
            return superstring;
          };
          
        const handleExportToTxt = async (e) => {
            try {
                e.preventDefault();
                const superstring = formatString(candidatoInfo);
                const blob = new Blob([superstring], { type: "text/plain" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = `${candidatoInfo.candidato.nomeCompleto}.txt`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                console.log("Superstring gerada e baixada com sucesso!");
            } catch (error) {
                console.log("Erro ao gerar a superstring:", error);
            }
        };

        const handleAprovar = async () => {
            try {
                await patchSituacaoCandidato(id, 'aprovado');
                alert('Candidato aprovado com sucesso!');
                window.location.reload();  
            } catch (error) {
                console.log('Erro ao aprovar candidato:', error);
            }
        }

        const handleReprovar = async () => {
            try {
                await patchSituacaoCandidato(id, 'reprovado');
                alert('Candidato reprovado com sucesso!');
                window.location.reload();  
            } catch (error) {
                console.log('Erro ao reprovar candidato:', error);
            }
        }

        if (candidatoInfo.documento.some(documento => documento.nomeDocumento === 'CNH')) {
            console.log('There is a document with nomeDocumento == CNH');
        } else {
            console.log('There is no document with nomeDocumento == CNH');
        }

        return (
            <>
            <TituloContainer>
                <VoltarContainer>
                    <Link to="/candidatos">
                        <img src={VoltarIcon} alt="Voltar" />
                    </Link>
                    <h1>Candidato</h1>
                </VoltarContainer>
                <BotoesContainer>
                    {candidatoInfo.candidato.situacao === 'APROVADO' && (
                        <>
                            <Botao cor="azul" texto="Exportar para Globus" onClick={(event) => handleExportToTxt(event)}/>
                        </>
                    )}
                    {candidatoInfo.candidato.situacao === 'NOVO' && (
                        <>
                            <Botao cor="azul" texto="Aprovar" onClick={handleAprovar}/>
                            <Botao cor="vermelho" texto="Reprovar" onClick={handleReprovar}/>
                        </>
                    )}
                </BotoesContainer>
            </TituloContainer>
            
        <CurriculoContainer>
            <CurriculoHeader>

                    { <h1>{candidatoInfo.candidato.nomeCompleto}</h1>}
                    { <h2>{candidatoInfo.candidato.nacionalidade}, {candidatoInfo.candidato.estadoCivil}, {calcularIdade(candidatoInfo.candidato.dataNascimento)} anos</h2>}
                    { <h3>{candidatoInfo.endereco.cidade}, {candidatoInfo.endereco.uf}</h3> } 

            </CurriculoHeader>
            <LinhaRosa />
            <DadosCandidatosContainer>
                <DadosCandidatosTitulo>
                    <h1>Dados do candidato:</h1>
                </DadosCandidatosTitulo>
                <DadosCandidatosInfoContainer>
                    <DadosCandidatosInfo>
                        <h2>Nome: {candidatoInfo.candidato.nomeCompleto}</h2>
                        <h2>Data de nascimento: {candidatoInfo.candidato.dataNascimento}</h2>
                        <h2>Estado civil: {candidatoInfo.candidato.estadoCivil}</h2>
                        <h2>Nacionalidade: {candidatoInfo.candidato.nacionalidade}</h2>
                        <h2>Nome da mãe: {candidatoInfo.candidato.nomeMae}</h2>
                    </DadosCandidatosInfo>
                    <DadosCandidatosInfo>
                        <h2>Gênero: {candidatoInfo.candidato.genero}</h2>
                        <h2>Dependentes: {candidatoInfo.candidato.numeroDependentes}</h2>
                        <h2>Necessidades especiais: {candidatoInfo.candidato.necessidadesEspeciais ? 'Sim' : 'Não' }</h2>
                        <h2>Raça: {candidatoInfo.candidato.raca}</h2>
                        <h2>Pronome: {candidatoInfo.candidato.pronome}</h2>
                    </DadosCandidatosInfo>
                </DadosCandidatosInfoContainer>
            </DadosCandidatosContainer>
            <LinhaRosa />
            <DadosCandidatosContainer>
                <DadosCandidatosTitulo>
                    <h1>Contato:</h1>
                </DadosCandidatosTitulo>
                <DadosCandidatosInfoContainer>
                    <DadosCandidatosInfo>
                        <h2>Telefone: {candidatoInfo.candidato.telefone}</h2>
                        <h2>Celular: {candidatoInfo.candidato.celular}</h2>
                    </DadosCandidatosInfo>
                    <DadosCandidatosInfo>
                        <h2>Email: {candidatoInfo.candidato.email}</h2>
                        <h2>Encontrou a vaga: {candidatoInfo.candidato.divulgacaoVaga}</h2>
                    </DadosCandidatosInfo>
                </DadosCandidatosInfoContainer>
            </DadosCandidatosContainer>
            <LinhaRosa />
            <DadosCandidatosContainer>
                <DadosCandidatosTitulo>
                    <h1>Endereço:</h1>
                </DadosCandidatosTitulo>
                <DadosCandidatosInfoContainer>
                    <DadosCandidatosInfo>
                        <h2>Rua: {candidatoInfo.endereco.rua}</h2>
                        <h2>Número: {candidatoInfo.endereco.numero}</h2>
                        <h2>CEP: {candidatoInfo.endereco.cep}</h2>
                    </DadosCandidatosInfo>
                    <DadosCandidatosInfo>
                        <h2>Cidade: {candidatoInfo.endereco.cidade}</h2>
                        <h2>Estado: {candidatoInfo.endereco.uf}</h2>
                        <h2>Bairro: {candidatoInfo.endereco.bairro}</h2>
                    </DadosCandidatosInfo>
                </DadosCandidatosInfoContainer>
            </DadosCandidatosContainer>
            <LinhaRosa />
            <DadosCandidatosContainer>
                <DadosCandidatosTitulo>
                    <h1>Documentos:</h1>
                </DadosCandidatosTitulo>
                {candidatoInfo.documento.map((documento, index) => {
                    const quantidadeDocumentos = candidatoInfo.documento.length;
                    return (
                        <React.Fragment key={index}>
                            <DadosCandidatosInfoContainer>
                                <DadosCandidatosInfo>
                                    <h2>Documento: {documento.nomeDocumento}</h2>
                                    <h2>Número: {documento.numero}</h2>
                                </DadosCandidatosInfo>
                                <DadosCandidatosInfo>
                                    <h2>Data de emissão: {documento.dataEmissão}</h2>
                                    <h2>Data de validade: {documento.dataValidade}</h2>
                                </DadosCandidatosInfo>
                            </DadosCandidatosInfoContainer>
                            {quantidadeDocumentos > 1 && index < quantidadeDocumentos - 1 && <LinhaCinza />}
                        </React.Fragment>
                    )
                })}
            </DadosCandidatosContainer>
            <LinhaRosa />
            <DadosCandidatosContainer>
                <DadosCandidatosTitulo>
                    <h1>Escolaridade:</h1>
                </DadosCandidatosTitulo>
                {candidatoInfo.escolaridade.map((escolaridade, index) => {
                    const quantidadeFormacoes = candidatoInfo.escolaridade.length;
                    return (
                        <React.Fragment key={index}>
                            <DadosCandidatosInfoContainer>
                                <DadosCandidatosInfo>
                                    <h2>Curso: {escolaridade.nomeCurso}</h2>
                                    <h2>Grau: {escolaridade.grau}</h2>
                                    <h2>Data de Início: {escolaridade.dataInicio}</h2>
                                    <h2>Data de Validade: {escolaridade.dataValidade}</h2>
                                </DadosCandidatosInfo>
                                <DadosCandidatosInfo>
                                    <h2>Curso de Transporte: {escolaridade.cursoTransporte ? 'Sim' : 'Não'}</h2>
                                    <h2>Observação: {escolaridade.observacao}</h2>
                                    <h2>Situação: {escolaridade.situacao}</h2>
                                </DadosCandidatosInfo>
                            </DadosCandidatosInfoContainer>
                            {quantidadeFormacoes > 1 && index < quantidadeFormacoes - 1 && <LinhaCinza />}
                        </React.Fragment>
                    )
                })}
            </DadosCandidatosContainer>
            <LinhaRosa />
            <DadosCandidatosContainer>
                <DadosCandidatosTitulo>
                    <h1>Experiência profissional:</h1>
                </DadosCandidatosTitulo>
                {candidatoInfo.experienciaProfissional.map((experiencia, index) => {
                    const quantidadeExperiencias = candidatoInfo.experienciaProfissional.length;
                    return (
                        <React.Fragment key={index}>
                            <DadosCandidatosInfoContainer>
                                <DadosCandidatosInfo>
                                    <h2>Empresa: {experiencia.nomeEmpresa}</h2>
                                    <h2>Cargo: {experiencia.cargo}</h2>
                                    <h2>Data de Início: {experiencia.dataAdmissao}</h2>
                                    <h2>Data de Saída: {experiencia.dataDemissoa}</h2>
                                </DadosCandidatosInfo>
                                <DadosCandidatosInfo>
                                    <h2>Descrição: {experiencia.descricao}</h2>
                                </DadosCandidatosInfo>
                            </DadosCandidatosInfoContainer>
                            {quantidadeExperiencias > 1 && index < quantidadeExperiencias - 1 && <LinhaCinza />}
                        </React.Fragment>
                    )
                })}

            </DadosCandidatosContainer>

            

        </CurriculoContainer>
        </>
    );
};

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Logo from '../../assets/logoOnpag.png';
import LogoutIcon from '../../assets/logout-icon.svg';
import SettingsIcon from '../../assets/settings-icon.svg';
import './styles'
import { FiltroContainer, LogoContainer, NavLinks, SidebarContainer, SidebarFooter, TicketsList } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSuccess } from '../../redux/auth/authActions';

import { moduloPublicacoes, moduloTickets, moduloCandidatos, moduloUsuarios, moduloUploads } from '../../modulos';

import { fetchFilas, getGruposdeAcesso } from '../../api';

const Sidebar = () => {
  const dispatch = useDispatch();

  const opcoes = [
    { id: 1, titulo: 'Mudar senha', path: '/usuario/mudar-senha' }
  ];

  const [opcoesAtivo, setOpcoesAtivo] = useState(false);
  const [ticketsAtivo, setTicketsAtivo] = useState(false);
  const [filas, setFilas] = useState(null);

  const grupos = useSelector((state) => state.auth.grupoAcesso);
  const modulosPermitidos = useSelector((state) => state.auth.modulos);
  const userId = useSelector((state) => state.auth.id);


  const modulos = {
    moduloPublicacoes,
    moduloTickets,
    moduloCandidatos,
    moduloUsuarios,
    moduloUploads
  };

  const handleClickOpcoes = () => {
    setOpcoesAtivo(!opcoesAtivo);
  }

  const handleClickTickets = () => {
    setTicketsAtivo(!ticketsAtivo);
  };

  const handleLogout = () => {
    dispatch(logoutSuccess());
  }

  // const userGrupoAcesso = async (userId) => {
  //    await getGruposdeAcesso(userId)
  //     .then((response) => {
  //       setGrupos(response);
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }


  useEffect(() => {
    const fetchFilasData = async () => {
      const filasData = await fetchFilas();
      setFilas(filasData);
    };
    fetchFilasData();

  }, []);

  // useEffect(() => {
  //   userGrupoAcesso(userId);
  //   console.log(grupos);
  // }, [userId,grupos]);

  const handlePermissions = () => {
    const acessGranted = {};
    grupos.forEach((grupo) => {
      Object.values(modulos).forEach((modulo) => {
        if (modulo.gruposAceitos.includes(grupo.id)) {
          acessGranted[modulo.name] = { ...modulo };
        }
      })
    });
    
    return acessGranted;
  }

  return (
    <SidebarContainer>

      {grupos === undefined && (dispatch(logoutSuccess()))}

      <LogoContainer>
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
      </LogoContainer>

      <NavLinks>
        {grupos && grupos.some(grupo => grupo.id === 1) ? (
          // Usuário é do grupo de acesso 1, portanto, renderize todos os módulos uma vez
          Object.values(modulos).map((modulo) => {
            if (modulo.name === "Tickets") {

              return (
                <>
                  <li onClick={handleClickTickets} key={modulo.name}>
                    <Link>
                      {modulo.icon}
                      <h2>{modulo.name}</h2>
                    </Link>
                  </li>
                  {ticketsAtivo && (
                    <TicketsList ativo={ticketsAtivo} >
                      {filas.map((fila) => (
                        <li key={fila.id}>
                          <Link to={`/tickets/${fila.id}`}>
                            <h2>{fila.name}</h2>
                          </Link>
                        </li>
                      ))}
                    </TicketsList>
                  )}
                </>
              );
            } else {
              return (
                <li key={modulo.name}>
                  <Link to={modulo.paths[0].path}>
                    {modulo.icon}
                    <h2>{modulo.name}</h2>
                  </Link>
                </li>
              );
            }
          })
        ) : (
          // Usuário não é do grupo de acesso 1, portanto, renderize apenas os módulos permitidos;
          Object.values(handlePermissions()).map((modulo) => {
            
              return modulo.name === "Tickets" ? (
                <>
                  <li onClick={handleClickTickets} key={modulo.name}>
                    <Link>
                      {modulo.icon}
                      <h2>{modulo.name}</h2>
                    </Link>
                  </li>
                  {ticketsAtivo && (
                    <TicketsList ativo={ticketsAtivo} >
                      {grupos.map((grupo) => (
                        <li key={grupo.id}>
                          <Link to={`/tickets/${grupo.id}`}>
                            <h2>{grupo.nome}</h2>
                          </Link>
                        </li>
                      ))}
                    </TicketsList>
                  )}
                </>
              ) : (
                  <li key={modulo.name}>
                    <Link to={modulo.paths[0].path}>
                      {modulo.icon}
                      <h2>{modulo.name}</h2>
                    </Link>
                  </li>
                );
            }))}
        
      </NavLinks>

      <SidebarFooter>
        <h2>Versão Beta</h2>
        <img src={SettingsIcon} onClick={handleClickOpcoes} alt="config" style={{ cursor: 'pointer' }} />
        {opcoesAtivo && (
          <FiltroContainer ativo={opcoesAtivo} onClick={handleClickOpcoes}>
            {opcoes.map((opcao) => (
              <li key={opcao.id}>
                <Link to={opcao.path}>
                  <h2>{opcao.titulo}</h2>
                </Link>
              </li>
            ))}
          </FiltroContainer>
        )}

        <Link to="/" onClick={handleLogout}>
          <img src={LogoutIcon} alt="logout" />
        </Link>
      </SidebarFooter>

    </SidebarContainer>
  )

}

export default Sidebar;



import React, { useContext } from 'react'
import Botao from '../../components/Botao'
import { Titulo } from '../../components/Titulo'
import { ContainerDados, ContainerImagem, ContainerMensagem, NovoAgendamentoContainer, NovoAgendamentoForm } from './styles'
import { AgendamentoInput } from '../../components/AgendamentoInput'
import { AgendamentoMensagemInput } from '../../components/AgendamentoMensagemInput'
import { AgendamentoImagemInput } from '../../components/AgendamentoImagemInput'
import { BotoesContainer } from '../../components/BotoesContainer'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTitulo } from '../../redux/novoAgendamento/novoAgendamentoActions'
import { setData } from '../../redux/novoAgendamento/novoAgendamentoActions'
import { setHora } from '../../redux/novoAgendamento/novoAgendamentoActions'
import { setDataTimestamp } from '../../redux/novoAgendamento/novoAgendamentoActions'
import { setMensagem } from '../../redux/novoAgendamento/novoAgendamentoActions'
import { setImagemPush } from '../../redux/novoAgendamento/novoAgendamentoActions'
import { setImagemInputValue } from '../../redux/novoAgendamento/novoAgendamentoActions'
import moment from 'moment'

const NovoAgendamento = () => {

    const dispatch = useDispatch();

    const titulo = useSelector((state) => state.novoAgendamento.titulo);
    const data = useSelector((state) => state.novoAgendamento.data);
    const hora = useSelector((state) => state.novoAgendamento.hora);
    const dataTimestamp = useSelector((state) => state.novoAgendamento.dataTimestamp);
    const mensagem = useSelector((state) => state.novoAgendamento.mensagem);
    const imagemPush = useSelector((state) => state.novoAgendamento.imagemPush);
    const imagemInputValue = useSelector((state) => state.novoAgendamento.imagemInputValue);
    
    const handleTituloChange = (event) => {
        const newTitulo = event.target.value;
        console.log(newTitulo);
        dispatch(setTitulo(newTitulo))
    }

    const handleDataChange = (event) => {
        const newData = event.target.value;
        const parts = newData.split('-');
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        console.log(formattedDate);
        dispatch(setData(newData))
    }

    const handleHoraChange = (event) => {
        const newHora = event.target.value
        console.log(newHora)
        dispatch(setHora(newHora))
    }

    const handleTimestampChange = () => {
        // concatenar a data e hora em uma string no formato "YYYY-MM-DD HH:SS"
        const dataHoraString = `${data} ${hora}`;
        console.log(dataHoraString)

        // criar um objeto Moment.js com a string da data e hora
        const dataHoraMoment = moment(dataHoraString, 'YYYY-MM-DD HH:mm');
        console.log(dataHoraMoment);

        // obter o timestamp usando o método unix()
        const dataTimestamp = dataHoraMoment.unix() * 1000;

        dispatch(setDataTimestamp(dataTimestamp))
    }

    const handleMensagemChange = (event) => {
        const newMensagem = event.target.value
        console.log(newMensagem)
        dispatch(setMensagem(newMensagem))
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
      
        reader.onload = (e) => {
          const byteArray = new Uint8Array(e.target.result);
          var binaryArray = [];
            for (var key in byteArray) {
            var binaryValue = byteArray[key].toString(2).padStart(8, '0');
            binaryArray.push(parseInt(binaryValue, 2));
            
            }
            dispatch(setImagemPush(binaryArray))
            dispatch(setImagemInputValue(file.name))

          console.log(binaryArray);
        };
      
        reader.readAsArrayBuffer(file);
      };

    useEffect(() => {
        console.log(imagemPush)
    }, [imagemPush])

    useEffect(() => {
        handleTimestampChange();
      }, [data, hora]);

    const verificarCampos = (e) => {
        if (titulo === "") {
            alert("Preencha o campo titulo")
            e.preventDefault()
            return false
        }
        if (data === "") {
            alert("Preencha o campo data")
            e.preventDefault()
            return false
        }
        if (hora === "") {
            alert("Preencha o campo hora")
            e.preventDefault()
            return false
        }
        if (mensagem === "") {
            alert("Preencha o campo mensagem")
            e.preventDefault()
            return false
        }

        if (dataTimestamp < Date.now()) {
            alert("A data e hora devem ser posteriores ao momento atual")
            e.preventDefault()
            return false
        }
        return true
    }

    return (
        <>
            <Titulo titulo="Novo Agendamento" />
            
            <NovoAgendamentoContainer>

                <NovoAgendamentoForm>

                        <ContainerDados>   

                            <AgendamentoInput 
                                label="Titulo da publicação*" 
                                type="text" 
                                placeholder="Titulo da publicação"
                                value={titulo} 
                                onChange={handleTituloChange}
                                width="45%" />

                            <AgendamentoInput
                                label="Data*"
                                type="date"
                                value={data}
                                onChange={handleDataChange}
                                width="20%" />

                            <AgendamentoInput
                                label="Hora*"
                                type="time"
                                value={hora}
                                onChange={handleHoraChange}
                                width="13%"
                                centerText />

                        </ContainerDados>

                        <ContainerMensagem>
                            
                            <AgendamentoMensagemInput 
                                label="Mensagem"
                                value={mensagem}
                                onChange={handleMensagemChange}
                                placeholder="Mensagem da publicação" />

                        </ContainerMensagem>

                        <ContainerImagem>

                            <AgendamentoImagemInput 
                                label="Anexar arquivo"
                                onChange={handleImageUpload}

                                 />

                        </ContainerImagem>

                    <BotoesContainer>

                            <Botao cor="vermelho" texto="Cancelar" link="/" />
                            <Botao cor="azul" texto="Avançar" link="selecionar-grupos" onClick={verificarCampos}/>

                    </BotoesContainer>
                
                </NovoAgendamentoForm>
                
            </NovoAgendamentoContainer>          
        </>       
    )
}

export default NovoAgendamento
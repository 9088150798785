import { styled } from "styled-components";

export const NovoAgendamentoContainer = styled.div`
    width: 90%;
    height: 85vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const NovoAgendamentoForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 80%;
    margin-top: 5%;
`

export const ContainerDados = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 20%;
`

export const ContainerMensagem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    height: 50%;
`

export const ContainerImagem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 90%;
    height: 20%;
`
const initialState = {
    candidatos: [],
    loading: true
};

const candidatosReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CANDIDATOS":
            return {
                ...state,
                candidatos: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

export default candidatosReducer;
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75vw;
    overflow: hidden;
`

export const FiltroContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;
    width: 100%;
    height: 10%;
    margin-top: 5%;
`

export const BotaoNovoAgendamento = styled.button`
        display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #ED2C6D;
    color: white;
    border-radius: 24px;
    border: none;
    font-size: 18px;
    cursor: pointer;
`

export const FiltroTitulo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    h2 {
        font-size: 24px;
        color: #5F6368;
    }

`

export const FiltroOpcoes = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: #F5F5F5;
    font-size: 24px;
    color: #313A46;
    cursor: pointer;
    z-index: 1;
    //same with as FiltroTitulo
    width: 300px;
`

export const FiltroOpcao = styled.div`
    h2 {
    font-size: 18px;
    color: #313A46;
    padding: 10px;
}
`

export const PublicacoesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    width: 100%;
    margin-top: 18px;
`

export const SemPublicacoes = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
    width: 100%;
    margin-top: 18px;
    min-height: 300px;

    h1 {
        font-size: 24px;
        color: #5F6368;
    }

    img {
        width: 100%;
        max-width: 400px;
    }

`

export const Mensagem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
`;

export const TituloPersonalizado = styled.h1`
    font-size: 36px;
    color: #5F6368;
`;

import Botao from "../../components/Botao";
import { Titulo } from "../../components/Titulo";
import { BotoesContainer } from "../../components/BotoesContainer";
import { Container } from "./styles";
import { TabelaFuncionarios } from "../../components/TabelaFuncionarios";
import { useSelector } from "react-redux";
import { pushMensagem } from "../../api";


const ConfirmarFuncionarios = () => {

    const { titulo, mensagem, dataTimestamp, imagemPush, funcionariosSelecionadosId } = useSelector((state) => state.novoAgendamento);


    const infoAgendamento = {
      titulo: titulo,
      text: mensagem || ' ',
      status: 'agendado',
      sendDate: dataTimestamp,
      file: imagemPush,
      userId: 1,
      employeeIdList: funcionariosSelecionadosId,
      type: 'PUSH',
    };

    console.log(infoAgendamento);

    const enviarAgendamento = async () => {

      try {
        const data = await pushMensagem(infoAgendamento);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    
    const handleConfirmarClick = async () => {
      try {
        await enviarAgendamento();
        // Navigate back to the homepage
        // Refresh the page
         
      } catch (error) {
        console.error('Error:', error);
      }
    };
    
    return (
        <>
            <Titulo titulo="Confirmar funcionários" />
            <Container>
                <TabelaFuncionarios modo="setor"/>
                <BotoesContainer>
                    <Botao cor="vermelho" texto="Voltar" link="/novo-agendamento/selecionar-grupos" />
                    <Botao cor="azul" texto="Confirmar" link="/" onClick={handleConfirmarClick} />          
                </BotoesContainer>
            </Container>
        </>
    )
}

export default ConfirmarFuncionarios;

import styled from 'styled-components';

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
    height: 60vh;
    width: 80vw;
    margin-top: 10vh;
`
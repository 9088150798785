import axios from 'axios';

import bcrypt from 'bcryptjs';

let fakeApi;
fakeApi = axios.create({ baseURL: localStorage.getItem('empresa') });

const api_candidatos = axios.create({
  baseURL: 'http://192.168.0.69:4401',
});

const audioApiFake = axios.create({ baseURL: 'http://172.16.0.153:3001' });

export const autenticarUsuario = async (usuario, senha, empresa) => {

  const portaApi = ['0', 'http://192.168.0.69:6602', 'http://192.168.0.69:4441'];

  const Login = axios.create({
    baseURL: portaApi[empresa]
  });

  localStorage.setItem('empresa', portaApi[empresa]);
  fakeApi = { ...Login };

  try {

    const response = await Login.post('/usuarios/autenticacao', {
      nomeDeUsuario: usuario,
    });



    if (response.status === 204) {
      // Usuário não encontrado, exibir mensagem de erro
      // alert('Usuário ou senha incorretos');
      // handleShowModal();
      return false;
    }


    if (response.status === 200) {
      const responseData = response.data;
      console.log(responseData);
      // Nome de usuário encontrado, obter a hash correspondente
      const hash = responseData.hash;

      // Comparar a senha informada com a hash
      const senhaCorreta = bcrypt.compareSync(senha, hash);

      if (senhaCorreta) {

        // Senha correta, retornar os dados do usuário
        return true;
      }

      // Senha incorreta, exibir mensagem de erro
      // alert('UsuárioSSSS ou senha incorretos');
      return false;

    }
  } catch (error) {
    return false;
    // console.error('Erro ao fazer a requisição:', error);
    // throw error;
  }
};

export const checarSenha = async (usuario) => {
  try {
    const response = await fakeApi.post('/usuarios/autenticacao', {
      nomeDeUsuario: usuario,
    });
    const responseData = response.data;
    console.log(responseData);
    // Nome de usuário encontrado, obter a hash correspondente
    const hash = responseData.hash;


    return hash;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};


export const fetchPublicacoes = async () => {
  try {
    const response = await fakeApi.get('/mensagens/tipos/PUSH');
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchUsuarios = async () => {
  try {
    const response = await fakeApi.get('/usuarios');
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchUsuario = async (obj) => {
  try {
    // console.log(JSON.stringify(obj));
    const response = await fakeApi.post('/usuarios', obj);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisiçãozinha linda:', error);
    throw error;
  }
}

export const fetchFilas = async () => {
  try {
    const response = await fakeApi.get('/filas');
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
}

export const fetchSetores = async () => {
  try {
    const response = await fakeApi.get('/funcionarios/setores');
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchSetor = async (setor) => {
  try {
    const response = await fakeApi.get(`/funcionarios/setores/${setor}`);
    return [response.data];
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchFuncionarios = async () => {
  try {
    const response = await fakeApi.get('/funcionarios');
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const pushMensagem = async (infoAgendamento) => {
  try {
    const response = await fakeApi.post('/mensagens/push-mensagens', infoAgendamento);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchTickets = async () => {
  try {
    const response = await fakeApi.get('/tickets');
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchTicketsByFilaId = async (id) => {
  try {
    const response = await fakeApi.get(`/tickets/fila-id/${id}`);
    if (response.status === 204) {
      return [];
    }
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchTicketInteraction = async (id) => {
  try {
    const response = await fakeApi.get(`/interacoes/ticket-id/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchFuncionarioById = async (id) => {
  try {
    console.log(id);
    const response = await fakeApi.get(`/funcionarios/chapa/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchUserByEmail = async (email) => {
  try {
    const response = await fakeApi.get(`/usuarios/email/${email}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchTicketChat = async (id) => {
  try {
    const response = await fakeApi.get(`/tickets/historico/ticket-id/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchTicketById = async (id) => {
  try {
    const response = await fakeApi.get(`/tickets/id/${id}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const pushTicket = async (ticket) => {
  try {
    const response = await fakeApi.post('/mensagens/ticket-mensagens', ticket);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const updateTicketStatus = async (id, status) => {
  try {
    const response = await fakeApi.patch(`/tickets/atualizacao/id/${id}/status/${status}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const cancelPush = async (id) => {
  try {
    const response = await fakeApi.patch(`/mensagens/id/${id}/status/CANCELADO`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const changePassword = async (id, newPassword) => {
  try {
    const response = await fakeApi.patch(`/usuarios`, {
      id: id,
      novaSenha: newPassword,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const getUsuarios = async () => {
  try {
    const response = await fakeApi.get('/usuarios');
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const getUsuarioByEmail = async (email) => {
  try {
    const response = await fakeApi.get(`/usuarios/email/${email}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const getGruposdeAcesso = async (id) => {
  try {
    const response = await fakeApi.get(`/usuarios/grupo-acesso/${id}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    return error;
  }
}
//mod
// FUNÇÃO CRIADA PARA ATUALIZAR OS GRUPOS DE ACESSO DE UM USUÁRIO
export const putGruposdeAcesso = async (id, grupos) => {
  try {
    const response = await fakeApi.put(`/usuarios/grupo-acesso/${id}`, grupos);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    return error;
  }
};

export const postUserGrupodeAcesso = async (id, permissao) => {
  try {
    const response = await fakeApi.post(`/usuarios/grupo-acesso`, {
      usuarioId: id,
      grupoAcessoId: permissao,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    return error;
  }
}

export const getAllGruposdeAcesso = async () => {
  try {
    const response = await fakeApi.get(`/grupo-acesso`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    return error;
  }
}

export const resetPassword = async (id, senha) => {
  try {
    const response = await fakeApi.patch(`/usuarios`, {
      id: id,
      novaSenha: senha
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    return error;
  }
}

//USAR API_CANDIDATOS EM PRODUCAO
export const fetchCandidatos = async () => {
  try {
    const response = await api_candidatos.get('/curriculos');
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const fetchCandidatoById = async (id) => {
  try {
    const response = await api_candidatos.get(`/curriculos/candidato-id/${id}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const patchSituacaoCandidato = async (id, situacao) => {
  try {
    const response = await api_candidatos.patch(`/curriculos/candidato-id/${id}/situacao/${situacao}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
};

export const buscaTicketsPorFilaId = async (id) => {
  try {
    const response = await fakeApi.get(`/tickets/fila-id/${id}`);
    const tratado = response.data.map((ticket)=>{
      return {
        data: ticket.data,
        fila: ticket.filaId,
        id: ticket.id,
        funcionario: ticket.idDonoTicket,
        status: ticket.status,
        titulo: ticket.titulo,
      }

    });
    tratado.forEach(async (ticket) => {
      const funcionario = await fetchFuncionarioById(ticket.funcionario);
      ticket.funcionario = funcionario;
    });
    return tratado;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
}

export const buscaAudio = async ()=>{
  try {
    const response = await audioApiFake.get();
    return response;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
  }
}

export default fakeApi;
import styled from 'styled-components';

export const TabelaContainer = styled.div`
    width: 100%;
    height: 80%;
    margin-top: 20px;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 40px;
`
export const TabelaHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #000;
`

export const TabelaHeaderID = styled.div`
    width: 10%;
    border-right: 1px solid #000;
    font-size: 18px;
    text-align: start;
    padding-left: 16px;
`

export const TabelaHeaderNome = styled.div`
    width: 33%;
    border-right: 1px solid #000;
    font-size: 18px;
    padding-left: 16px;

    h1 {
        font-size: 18px;
        font-weight: 600;
    }

    h2 {
        font-size: 14px;
        font-weight: 400;
        color: #5F6368;
    }
`

export const TabelaHeaderInfo = styled.div`
    width: 52%;
    border-right: 1px solid #000;
    font-size: 18px;
    padding-left: 16px;

    h1 {
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
    }

    h2 {
        font-size: 14px;
        font-weight: 400;
        color: #5F6368;
    }
`

export const TabelaHeaderStatus = styled.div`
    width: 15%;
    font-size: 18px;
    padding-left: 16px;
`

export const TabelaBody = styled.div`
    display: flex;
    flex-direction: column;
    height: 85%;
    overflow-y: scroll;
`

export const TabelaLinha = styled.div`    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #000;
    padding: 10px;
    cursor: pointer;
    
    &:hover {
        background: #F1F3F4;
    }
`
export const StatusBox = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 10px;
    border: 0.7px solid #00ff19;
    background: rgba(0, 255, 25, 0.17);
    justify-content: center;
    font-size: 16px;
    color: #00ff19;
    text-align: center;

    // dependendo do status, muda a cor da borda, e o background (EM ANALISE = amarelo, APROVADO = verde, REPROVADO = vermelho)
    border: ${props => props.status === 'NOVO' ? '0.7px solid #FFC107' : props.status === 'APROVADO' ? '0.7px solid #00ff19' : '0.7px solid #FF0000'};
    background: ${props => props.status === 'NOVO' ? 'rgba(255, 193, 7, 0.17)' : props.status === 'APROVADO' ? 'rgba(0, 255, 25, 0.17)' : 'rgba(255, 0, 0, 0.17)'};
    color: ${props => props.status === 'NOVO' ? '#FFC107' : props.status === 'APROVADO' ? '#00ff19' : '#FF0000'};

    img svg {
        color: ${props => props.status === 'NOVO' ? '#FFC107' : props.status === 'APROVADO' ? '#00ff19' : '#FF0000'};
    }
`
export const TabelaBodyItemNome = styled.div`
    width: 33%;
    display: flex;
    justify-content: start;
    border-right: 1px solid #000;
`

export const TabelaBodyInfo = styled.div`
    width: 52%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-right: 1px solid #000;
    padding-left: 16px;

    h1 {
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
    }

    h2 {
        font-size: 14px;
        font-weight: 400;
        color: #5F6368;
    }
`

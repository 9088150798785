import React from 'react';
import { Container } from './styles';

export const AgendamentoInput = ({ label, type, placeholder, onChange, value, width, centerText }) => {
  
  return (
    <Container width={width} centerText={centerText}>
      <label>{label}</label>
      <input type={type} placeholder={placeholder} value={value} onChange={onChange} />
    </Container>
  );
};
import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 75vw;
    overflow: hidden;
    min-height: 100vh;
`

export const FiltroContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 0 10%;
    width: 100%;
    height: 10%;
    margin-top: 5%;
`

export const FiltroTitulo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 24px;
        color: #5F6368;
    }

`

export const FiltroOpcoes = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: #F5F5F5;
    font-size: 24px;
    color: #313A46;
    cursor: pointer;
    z-index: 1;
    // same width as FiltroTitulo
    width: 300px;
`

export const FiltroOpcao = styled.div`
    h2 {
    font-size: 18px;
    color: #313A46;
    padding: 10px;
}
`

export const CheckboxFilialContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;

    `

export const CheckboxFilial = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;

    input {
        margin-right: 10px;
    }

    label {
        font-size: 1.2rem;
        color: #5F6368;
    }

`



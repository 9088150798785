import React from 'react';
import './styles.js';
import { Link } from 'react-router-dom';
import { BotaoContainer } from './styles.js';

const Botao = ({ cor, texto, link, onClick, }) => {
  
    return (
        <Link onClick={onClick} to={link}>
          <BotaoContainer  cor={cor}>{texto}</BotaoContainer>
        </Link>
      );
    }

export default Botao;
const initialState = {
    titulo: '',
    data: '',
    dataTimestamp: '',
    hora: '',
    mensagem: '',
    imagemPush: '',
    imagemInputValue: '',
    funcionarios: [],
    setores: [],
    funcionariosSelecionados: [],
    funcionariosSelecionadosId: [],
    setoresSelecionados: [],
    grupos: [],
    setoresSelecionadosId: [],
}

const novoAgendamentoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TITULO':
            return {
                ...state,
                titulo: action.payload
            }
        case 'SET_DATA':
            return {
                ...state,
                data: action.payload
            }
        case 'SET_HORA':
            return {
                ...state,
                hora: action.payload
            }
        case 'SET_DATA_TIMESTAMP':
            return {
                ...state,
                dataTimestamp: action.payload
            }
        case 'SET_MENSAGEM':
            return {
                ...state,
                mensagem: action.payload
            }
        case 'SET_IMAGEM_PUSH':
            return {
                ...state,
                imagemPush: action.payload
            }
        case 'SET_IMAGEM_INPUT_VALUE':
            return {
                ...state,
                imagemInputValue: action.payload
            }
        case 'SET_FUNCIONARIOS':
            return {
                ...state,
                funcionarios: action.payload
            }
        case 'SET_SETORES':
            return {
                ...state,
                setores: action.payload
            }
        case 'SET_FUNCIONARIOS_SELECIONADOS':
            return {
                ...state,
                funcionariosSelecionados: action.payload
            }
        case 'SET_FUNCIONARIOS_SELECIONADOS_ID':
            return {
                ...state,
                funcionariosSelecionadosId: action.payload
            }
        case 'SET_SETORES_SELECIONADOS':
            return {
                ...state,
                setoresSelecionados: action.payload
            }
        case 'SET_GRUPOS':
            return {
                ...state,
                grupos: action.payload
            }
        case 'SET_SETORES_SELECIONADOS_ID':
            return {
                ...state,
                setoresSelecionadosId: action.payload
            }
        default:
            return state;
    }
}

export default novoAgendamentoReducer;
import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import { persistReducer } from 'redux-persist';
import persistConfig from './persistConfig';
import candidatosReducer from './candidatos/candidatosReducer';
import publicacoesReducer from './publicacoes/publicacoesReducer';
import ticketsReducer from './tickets/ticketsReducer';
import novoAgendamentoReducer from './novoAgendamento/novoAgendamentoReducer';

const rootReducer = combineReducers({
    // add reducers
    auth: authReducer,
    candidatos: candidatosReducer,
    publicacoes: publicacoesReducer,
    tickets: ticketsReducer,
    novoAgendamento: novoAgendamentoReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
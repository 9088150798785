import { Tabela, CabeçalhoTabela, CelulaTabela, LinhaTabela, CelulaCabeçalho, CorpoTabela, CelulaTabelaId } from './styles'


export const TabelaHistoricoUploads = (arrayHistorico) => {
    const historico = [
        {
            id: 1,
            data: "01/03/2024",
            tipo: "Informe de Rendimentos",
            nomeArquivo: "InformeRendimentos2023.pdf"
        },{
            id: 2,
            data: "05/03/2024",
            tipo: "Holetite",
            nomeArquivo: "Holerite-03-2024.pdf"
        },{
            id: 3,
            data: "05/04/2024",
            tipo: "Holerite",
            nomeArquivo: "Holerite-04-2024.pdf"
        },{
            id: 4,
            data: "05/05/2024",
            tipo: "Holerite",
            nomeArquivo: "Holerite-05-2024.pdf"
        },{
            id: 5,
            data: "05/06/2024",
            tipo: "Holerite",
            nomeArquivo: "Holerite-06-2024.pdf"
        }
    ];
    
    return (
        <Tabela>
            <CabeçalhoTabela>
                <LinhaTabela>
                    <CelulaCabeçalho>ID</CelulaCabeçalho>
                    <CelulaCabeçalho>Data</CelulaCabeçalho>
                    <CelulaCabeçalho>Tipo</CelulaCabeçalho>
                    <CelulaCabeçalho>Nome do arquivo</CelulaCabeçalho>
                </LinhaTabela>
            </CabeçalhoTabela>
            <CorpoTabela>
                { historico.map((item, index) => (
                    <LinhaTabela key={index}>
                        <CelulaTabelaId>{item.id}</CelulaTabelaId>
                        <CelulaTabela>{item.data}</CelulaTabela>
                        <CelulaTabela>{item.tipo}</CelulaTabela>
                        <CelulaTabela>{item.nomeArquivo}</CelulaTabela>
                    </LinhaTabela>
                ))}
            </CorpoTabela>
        </Tabela>
    );
};
import React, { useState, useEffect } from 'react'
import { fetchUsuarios, resetPassword, getAllGruposdeAcesso, getGruposdeAcesso, putGruposdeAcesso, fetchUsuario } from '../../api'
import { BotaoAcao, BotaoContainer, Container, UsuarioCard, CardsContainer, AvatarContainer, InfoContainer, JanelaStyle, JanelaHeader, AcoesContainer, Acao, PermissoesContainer, Permissoes, Permissao, FullInfoContainer, Col1, BotaoContainerFim,IconesContainer } from './styles'
import { Titulo } from '../../components/Titulo'
import TemplateAvatar from '../../assets/avatar-template.png'
import KeyIcon from '../../assets/key-icon.svg'
import CloseIcon from '../../assets/close-icon.svg'
import bcrypt from 'bcryptjs'


const JanelaUsuario = ({ usuario, permissoes, permissoesUsuarioOriginais, closeJanela }) => {
    
    const [permissoesSelecionadas, setPermissoesSelecionadas] = useState([]);
    const [permissaoParaAdicionar, setPermissaoParaAdicionar] = useState([]);

    useEffect(() => {
        setPermissoesSelecionadas(permissoesUsuarioOriginais);
    }, [permissoesUsuarioOriginais, usuario]);

    if (!usuario) return null;
    
    const handleSave = async () => {
        const usuarioId = usuario.id;
        
        await putGruposdeAcesso(usuarioId, permissoesSelecionadas);
        
        closeJanela()

        // let permissionsToAdd = [];
        // permissionsToAdd = permissoesSelecionadas.filter((permissao) => !permissoesUsuarioOriginais.includes(permissao));
        
        // permissionsToAdd.forEach(async (permissao) => {
        //     await postUserGrupodeAcesso(usuarioId, permissao);
        // });

    }

    const changePassword = async (id) => {
        const senhaNovaBcrypt = await bcrypt.hash("123@Mudar", 10)
        await resetPassword(id, senhaNovaBcrypt)
        alert("Senha resetada para 123@Mudar , instrua o usuário a mudar a senha no primeiro login!")
        closeJanela()
    }

    return (
        <JanelaStyle>

            <JanelaHeader>
                <AvatarContainer>
                    <img src={TemplateAvatar} alt="Avatar" onClick={() => (closeJanela())} />
                </AvatarContainer>
                <div>
                    <h2>{usuario.nomeDeUsuario}</h2>
                    <p>{usuario.email}</p>
                </div>
                <IconesContainer>
                    <img src={CloseIcon} alt="Fechar" onClick={() => (closeJanela())} />
                </IconesContainer>
            </JanelaHeader>
            
            <AcoesContainer>
                <Acao>
                    <img src={KeyIcon} alt="Resetar Senha" />
                    <p onClick={() => changePassword(usuario.id)}>Resetar Senha</p>
                </Acao>
            </AcoesContainer>

            <FullInfoContainer>
                <h3>Informações</h3>
                <Col1>
                    <h4>E-mail</h4>
                    <p>{usuario.email}</p>
                </Col1>
                <Col1>
                    <h4>Telefone</h4>
                    <p>{usuario.telefone}</p>
                </Col1>
                <Col1>
                    <h4>Chapa</h4>
                    <p>{usuario.funcionarioId ? usuario.funcionarioId : "Sem chapa"}</p>
                </Col1>
            </FullInfoContainer>

            <PermissoesContainer>
                <h3>Permissões</h3>
                <Permissoes>
                {permissoes.map((permissao) => (
                    <Permissao key={permissao.id}>
                        <input
                            type="checkbox"
                            id={permissao.id}
                            name={permissao.nome}
                            value={permissao.nome}
                            checked={permissoesSelecionadas.includes(permissao.id)}
                            onChange={(e) => {
                                const checked = e.target.checked;
                                if (checked) {
                                    setPermissoesSelecionadas([...permissoesSelecionadas, permissao.id]);
                                } else {
                                    setPermissoesSelecionadas(permissoesSelecionadas.filter((id) => id !== permissao.id));
                                }
                            }}
                            
                        />
                        <label htmlFor={permissao.nome}>{permissao.nome}</label>
                    </Permissao>
                ))}
                </Permissoes>
            </PermissoesContainer>

            <BotaoContainerFim>
                <BotaoAcao onClick={() => handleSave()}>Salvar</BotaoAcao>
            </BotaoContainerFim>

        </JanelaStyle>
    );
};

const JanelaNovoUsuario = ({closeJanela}) => {

    const [usuario, setUsuario] = useState(null)
    const [email, setEmail] = useState(null)
    const [telefone, setTelefone] = useState(null)
    const [chapa, setChapa] = useState(null)

    const handleCriarUsuario = async () => {
        const usuarioData = {
            senha: "123@Mudar",
            nomeDeUsuario: usuario,
            email: email,
            telefone: telefone,
            funcionarioId: chapa
        }
        const res = await fetchUsuario(usuarioData);
        console.log(res);
        closeJanela();
    }

  

    return (
        <JanelaStyle>

            <JanelaHeader>
                <AvatarContainer>
                    <img src={TemplateAvatar} alt="Avatar" />
                </AvatarContainer>
                <div>
                    <h2>Novo Usuário</h2>
                </div>
                <IconesContainer>
                    <img src={CloseIcon} alt="Fechar" onClick={()=>(closeJanela())} />
                </IconesContainer>
            </JanelaHeader>

            <FullInfoContainer>
                <h3>Dados</h3>
                <Col1>
                    <h4>Nome de usuário*</h4>
                    <input type="text" id="nomeDeUsuario" name="nomeDeUsuario" value={usuario} onChange={(e) => setUsuario(e.target.value)} />

                    <h4>E-mail*</h4>
                    <input type="text" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />

                    <h4>Telefone*</h4>
                    <input type="number" id="telefone" name="telefone" value={telefone} onChange={(e) => setTelefone(e.target.value)} />

                    <h4>Chapa</h4>
                    <input type="number" id="chapa" name="chapa" value={chapa} onChange={(e) => setChapa(e.target.value)} />
                </Col1>
            </FullInfoContainer>

            <BotaoContainerFim>
                <BotaoAcao onClick={() => handleCriarUsuario()}>Criar</BotaoAcao>
            </BotaoContainerFim>

        </JanelaStyle>
    );
};


export const EditarUsuarios = () => {

    const [usuarios, setUsuarios] = useState([])
    const [usuario, setUsuario] = useState(null)
    const [permissoesUsuario, setPermissoesUsuario] = useState([])
    const [permissoes, setPermissoes] = useState([])
    const [janelaNovoUsuario, setJanelaNovoUsuario] = useState(false)

    const getUsuarios = async () => {
        const usuarios = await fetchUsuarios()
        setUsuarios(usuarios)
    }

    const fetchGruposdeAcesso = async () => {
        const permissoesData = await getAllGruposdeAcesso()
        setPermissoes(permissoesData)
    }

    const fetchUsuariosPermissoes = async (id) => {
        const permissoesUsuarioData = await getGruposdeAcesso(id)
        const novasPermissoes = permissoesUsuarioData.map(permissao => permissao.id);
        setPermissoesUsuario(novasPermissoes);
    }

    const closeJanela = () => {
        setUsuario(null);
        console.log(usuario)
    }

    const closeJanelaNovoUsuario = () => {
        setJanelaNovoUsuario(false);
    
    }

    useEffect(() => {
        getUsuarios()
        fetchGruposdeAcesso()
    }, [])

    useEffect(() => {
        if (!usuario) return undefined
        fetchUsuariosPermissoes(usuario.id)
    }, [usuario])

    useEffect(() => {
        console.log(permissoes)
    }, [permissoes])

    return (
        <>
        <Titulo titulo="Usuários" />
        <Container>

            <BotaoContainer>
                <BotaoAcao onClick={() => setJanelaNovoUsuario(true)}>Novo Usuário</BotaoAcao>
            </BotaoContainer>

            <CardsContainer>
                {usuarios.map((usuario) => (
                    <UsuarioCard onClick={() => (setUsuario(usuario))} key={usuario.id} selected={usuario.id === usuario.id}>
                        <AvatarContainer>
                            <img src={TemplateAvatar} alt="Avatar" />
                        </AvatarContainer>
                        <InfoContainer>
                            <p>{usuario.nomeDeUsuario}</p>
                        </InfoContainer>
                    </UsuarioCard>
                ))}
            </CardsContainer>

            {usuario ? <JanelaUsuario usuario={usuario} permissoes={permissoes} permissoesUsuarioOriginais={permissoesUsuario} closeJanela={() => closeJanela()} /> : null}
            
            {janelaNovoUsuario ? <JanelaNovoUsuario closeJanela={() => closeJanelaNovoUsuario()}/> : null}

        </Container>
        </>
    )

}

export default EditarUsuarios

import React, { useEffect } from 'react'
import Botao from '../../components/Botao'
import { Titulo } from '../../components/Titulo'
import { BotoesContainer } from '../../components/BotoesContainer'
import { Container, Coluna, SetorInput } from './styles'
import { fetchSetores } from '../../api'
import { useDispatch, useSelector } from 'react-redux'
import { setSetores } from '../../redux/novoAgendamento/novoAgendamentoActions'
import { setSetoresSelecionados } from '../../redux/novoAgendamento/novoAgendamentoActions'

const SelecionarSetores = () => {

    const dispatch = useDispatch();

    const setores = useSelector((state) => state.novoAgendamento.setores);
    const setoresSelecionados = useSelector((state) => state.novoAgendamento.setoresSelecionados);

    console.log(setores)

    useEffect(() => {
        const fetchSetoresData = async () => {
            try {
                const dadosSetores = await fetchSetores();
                dispatch(setSetores(dadosSetores));
                console.log(dadosSetores); // Make sure you log the resolved data (dadosSetores) and not the Promise itself
            } catch (error) {
                // Handle errors here if needed
            }
        };
    
        fetchSetoresData();
    }, []);

    const setoresColuna1 = setores.slice(0, setores.length / 2)
    const setoresColuna2 = setores.slice(setores.length / 2, setores.length)

    const handleSelecionarSetor = (setor) => {
        // Verificar se o setor já está selecionado
        if (setoresSelecionados.includes(setor)) {
          // Remover o setor dos setores selecionados
          const novosSetoresSelecionados = setoresSelecionados.filter((s) => s !== setor);
          dispatch(setSetoresSelecionados(novosSetoresSelecionados))
          
          console.log(setoresSelecionados)
        } else {
          // Adicionar o setor aos setores selecionados
          dispatch(setSetoresSelecionados([...setoresSelecionados, setor]))
          setSetoresSelecionados([...setoresSelecionados, setor]);
          console.log(setoresSelecionados)
        }
      };

    return (
        <>
            <Titulo titulo="Selecionar setores" />

            <Container>
                <Coluna>

                    {setoresColuna1.map((setor) => (
                        <SetorInput>
                            <input type="checkbox" checked={setoresSelecionados.includes(setor)} onChange={() => handleSelecionarSetor(setor)}></input>
                            <label >{setor}</label>
                        </SetorInput>
                    ))}

                </Coluna>
                <Coluna>

                    {setoresColuna2.map((setor) => (
                        <SetorInput>
                            <input type="checkbox" checked={setoresSelecionados.includes(setor)} onChange={() => handleSelecionarSetor(setor)}></input>
                            <label >{setor}</label>
                        </SetorInput>
                    ))}

                </Coluna>

            </Container>

            <BotoesContainer>
                <Botao cor="vermelho" texto="Voltar" link="/novo-agendamento/selecionar-grupos" />
                <Botao cor="azul" texto="Avançar" link="/novo-agendamento/confirmar-funcionarios" />
            </BotoesContainer>
        </>
    )
}

export default SelecionarSetores

import React from 'react';
import { Container } from './styles';

export const AgendamentoMensagemInput = ({ label, placeholder, value, onChange }) => {
  
  return (
    <Container>
      <label>{label}</label>
      <textarea value={value} placeholder={placeholder} onChange={onChange}  />
    </Container>
  );
};
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
    height: 60vh;
    width: 80vw;
    margin-top: 10vh;
`    

export const Coluna = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;   
    width: 20%;
`

export const SetorInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: start;
    padding: 4px;

    label {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 1rem;
    }

    input {
    margin-right: 0.5rem;
    }
`
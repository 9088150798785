import { BotaoAdicionar, Container, Input } from "./styles";
import { useState } from "react";
import { setFuncionariosSelecionados } from "../../redux/novoAgendamento/novoAgendamentoActions";
import { fetchFuncionarioById } from "../../api";
import { useDispatch, useSelector } from "react-redux";

export const ProcurarFuncionario = () => {

  const [codigoFuncionario, setCodigoFuncionario] = useState("");

  const dispatch = useDispatch();
  const { funcionariosSelecionados } = useSelector((state) => state.novoAgendamento);
  
  const handleAdicionarFuncionario = async () => {
      try {
        const funcionario = await fetchFuncionarioById(codigoFuncionario);
        if (funcionario === "") {
          alert("Funcionário não encontrado");
          return;
        }
        if (funcionariosSelecionados.some(f => f.id === funcionario.id)) {
          alert("Funcionário já adicionado");
          return;
        }
        dispatch(setFuncionariosSelecionados([...funcionariosSelecionados, funcionario]));
      } catch (error) {
      console.error('Error:', error);
      }
    };
  
    return (
      <Container>
        <Input
          type="text"
          placeholder="Código do funcionário"
          value={codigoFuncionario}
          onChange={e => setCodigoFuncionario(e.target.value)}
        />
        <BotaoAdicionar onClick={handleAdicionarFuncionario} defaultChecked>Adicionar</BotaoAdicionar>
      </Container>
  );
};
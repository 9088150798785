
import ConfirmarFuncionarios from './pages/ConfirmarFuncionarios';
import Publicacoes from './pages/Publicacoes';
import SelecionarFuncionarios from './pages/SelecionarFuncionarios';
import SelecionarGrupos from './pages/SelecionarGrupos';
import SelecionarSetores from './pages/SelecionarSetores';
import { Tickets } from './pages/Tickets';
import { Ticket } from './pages/Ticket';
import { Candidatos } from './pages/Candidatos';
import { Candidato } from './pages/Candidato';
import { EditarUsuarios } from './pages/EditarUsuarios';
import { Uploads } from './pages/Uploads';
import CalendarIcon from './assets/calendar-icon.svg';
import ChatIcon from './assets/chat-icon.svg';
import PersonIcon from './assets/person-icon-white.svg';
import UploadsIcon from './assets/upload-file.svg';


// grupos de acesso
// 1 - Administrador
// 2 - RH
// 3 - DP
// 4 - Secretaria Tecnica

export const moduloPublicacoes = {
    name: 'Publicações',
    icon: <img src={CalendarIcon} alt="calendar" />,
    paths: [
        {
            path: '/publicacoes',
            component: Publicacoes,
            exact: true
        },
        {
            path: '/novo-agendamento',
            component: Publicacoes,
            exact: true
        },
        {
            path: '/novo-agendamento/selecionar-grupos',
            component: SelecionarGrupos,
            exact: true
        },
        {
            path: '/novo-agendamento/selecionar-setores',
            component: SelecionarSetores,
            exact: true
        },
        {
            path: '/novo-agendamento/confirmar-funcionarios',
            component: ConfirmarFuncionarios,
            exact: true
        },
        {
            path: '/novo-agendamento/selecionar-funcionarios',
            component: SelecionarFuncionarios,
            exact: true
        }
    ],
    gruposAceitos: [1, 2, 3]
}

export const moduloTickets = {
    name: 'Tickets',
    icon: <img src={ChatIcon} alt="chat" />,
    paths: [
        {
            path: '/tickets/:id',
            component: Tickets,
            exact: true
        },
        {
            path: '/ticket/:grupoId/:id',
            component: Ticket,
            exact: true
        }
    ],
    gruposAceitos: [1, 2, 3, 4]
}

export const moduloCandidatos = {
    name: 'Candidatos',
    icon: <img src={PersonIcon} alt="candidatos" />,
    paths: [
        {
            path: '/candidatos',
            component: Candidatos,
            exact: true
        },
        {
            path: '/candidato/:id',
            component: Candidato,
            exact: true
        }
    ],
    gruposAceitos: [1]
}

export const moduloUsuarios = {
    name: 'Usuários',
    icon: <img src={PersonIcon} alt="usuarios" />,
    paths: [
        {
            path: '/usuarios',
            component: EditarUsuarios,
            exact: true
        }
        // ,
        // {
        //     path: '/usuarios/:id',
        //     component: EditarUsuarios,
        //     exact: true
        // }
    ],
    gruposAceitos: [1]
}

export const moduloUploads = {
    name: 'Uploads',
    icon: <img src={UploadsIcon} alt="uploads" />,
    paths: [
        {
            path: '/uploads',
            component: Uploads,
            exact: true
        }
    ],
    gruposAceitos: [1, 3]
}


const initialState = {
    tickets: [],
    usuarios: []
}

const ticketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TICKETS':
            return {
                ...state,
                tickets: action.payload
            }
        case 'SET_USUARIOS':
            return {
                ...state,
                usuarios: action.payload
            }
        default:
            return state;
    }
}

export default ticketsReducer;
import { Button, Container, Form, Input, Logo, TitleContainer, Select } from './styles';
import LogoOnpag from '../../assets/logoOnpag.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { autenticarUsuario, getGruposdeAcesso, getUsuarioByEmail } from '../../api';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../redux/auth/authActions';
import { Modal, handleShowModal, handleCloseModal } from '../../components/Modal';


export const Login = () => {
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [empresa, setEmpresa] = useState('');

    //alert(window.location.href);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        console.log(empresa)

        const autenticado = await autenticarUsuario(usuario, senha, empresa);

        if (autenticado === true) {

            // checar o dominio do usuario, se for @attransportes empresa = ATT
            // se for @radialtransportes empresa = Radial
            // se for @onpag empresa = Onpag

            let empresa = ''
            if (usuario.includes('@attransporte')) {
                empresa = 'ATT'
            }
            if (usuario.includes('@radialtransporte')) {
                empresa = 'Radial'
            }

            const usuarioInfo = await getUsuarioByEmail(usuario)

            const usuarioId = usuarioInfo.id

            const grupoAcesso = await getGruposdeAcesso(usuarioId)
            console.log(grupoAcesso);

            if (grupoAcesso[0] === undefined) {
                alert('Usuário não possui grupo de acesso, por favor, contate o administrador do sistema.');
            }

            const user = {
                id: usuarioId,
                usuario: usuario,
                empresa: empresa,
                grupoAcesso: grupoAcesso
            };

            dispatch(loginSuccess(user));

            navigate('/');
        }else{
            handleShowModal();
    };}

    const handleUsuario = (e) => {
        setUsuario(e.target.value);
    };

    const handleSenha = (e) => {
        setSenha(e.target.value);
    };

    const handleEmpresa = (e) => {
        setEmpresa(e.target.value);
    };

    return (
        <Container>
                <Modal titulo='Falha ao realizar login' mensagem='Usuário ou senha incorretos' botao='Fechar' />
            <Form onSubmit={handleLogin}>
                <TitleContainer>
                    <h1>Sistema SGT</h1>
                    <Logo src={LogoOnpag} alt="Logo" />
                </TitleContainer>
                <Input placeholder="Usuário" onChange={handleUsuario} required/>
                <Input placeholder="Senha" type='password' onChange={handleSenha} required/>

                <Select onChange={handleEmpresa} required>
                    <option value="" defaultValue={""}>Selecione a empresa</option>
                    <option value="1">Radial</option>
                    <option value="2">ATT</option>
                </Select>
                <Button type="submit">Entrar</Button>
            </Form>
        </Container>
    );
};
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    gap: 1rem;
    width: 100%;
    `

export const Input = styled.input`
    width: 20rem;
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    `

export const BotaoAdicionar = styled.button`
    width: 8rem;
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    background-color: #2D9CDB;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #2D9CDB;
        color: #fff;
        cursor: pointer;
    }
    `
import React from "react";
import { Container } from "./styles";

export const AgendamentoImagemInput = ({ label, onChange, imagemInputValue }) => {

    return (
        <Container>
            <label>{label}</label>
            <input type="file" onChange={onChange} value={imagemInputValue}/>
        </Container>
    );
}
import styled, { css } from 'styled-components';

export const BotaoContainer = styled.button`
  height: 40px;
  border: none;
  border-radius: 22px;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;

  ${props =>
    props.cor === 'padrao' &&
    css`
      background-color: #313a46;
    `}

  ${props =>
    props.cor === 'vermelho' &&
    css`
      background-color: #d63636;
    `}

  ${props =>
    props.cor === 'azul' &&
    css`
        background-color: #2D9CDB;
    `}
`;
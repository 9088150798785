import styled from "styled-components";

export const Tabela = styled.table`
    width: 80%;
    background-color: #fff ;
    border-collapse: collapse;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 5px;
`;

export const CabeçalhoTabela = styled.thead`
    // border-radius: 20px;
`;

export const CelulaCabeçalho = styled.th`
    font-weight: bold;
    padding: 10px;
    font-size: 1.2rem;
`;

export const LinhaTabela = styled.tr`
    
`;

export const CorpoTabela = styled.tbody`
    background-color: #fff
`;

export const CelulaTabela = styled.td`    
    padding: 10px;
`;

export const CelulaTabelaId = styled.td`
    padding-left: 15px;
`;
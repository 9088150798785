import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`


    * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

a {
    text-decoration: none;
    display: contents;
}

`
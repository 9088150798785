import styled from 'styled-components';

export const Container = styled.div`
    width: ${(props) => props.width || '100%'};
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: 100%;
    gap: 10px;

    input {
      width: 100%;
      height: 55px;
      border: none;
      border-radius: 22px;
      padding-left: 10px;
      font-size: 18px;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: ${(props) => (props.centerText ? 'center' : 'left')};
  }
`;
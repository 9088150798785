import styled from 'styled-components'

export const TituloContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 2rem;
    width: 100%;
    padding: 0 3rem;
    margin-top: 2rem;

    img {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        cursor: pointer;
    }

    h1 {
        font-size: 2rem;
        font-weight: 400;
        color: #333333;
        padding-left: 1rem;
    }
`;


export const CurriculoContainer = styled.div`
    display: flex;
    padding: 10px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background-color: #fff;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 10px;

    h1 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

export const CurriculoHeader = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    background-color: #fff;
    margin-top: 1rem;

    
    h1 {
        color: #000;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    h2 {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    h3 {
        color: #595959;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

`;

export const LinhaRosa = styled.div`
    height: 4px;
    align-self: stretch;
    background: #ED2C6D;
`

export const DadosCandidatosContainer = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    `

export const DadosCandidatosTitulo = styled.div`
    display: flex;
    `

export const DadosCandidatosInfoContainer = styled.div`
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    `

export const DadosCandidatosInfo = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    `

export const LinhaCinza = styled.div`
    height: 1px;
    align-self: stretch;
    background: #595959;
`

export const VoltarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    `

export const BotoesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 30px;
    `
import styled from 'styled-components';


export const ModalComponent = styled.dialog`
    width: 400px;
    height: 300px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 30px;
    left: 0;
    right: 0;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 0px 10px #777777;
    animation: fade-in 0.1s ease-in;

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;
export const ModalHeader = styled.div`
    width: 100%;
    height: 60px;
    background-color: #282d35;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ModalTitle = styled.h1`
    color: #ffffff;
    font-size: 24px;
    margin-left: 20px;
`;

export const ButtonX = styled.button`
    height: 100%;
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
    margin-right: 20px;
`;

export const ModalBody = styled.div`
    width: 100%;
    height: 180px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalMessage = styled.p`
    font-size: 20px;
    color: #282d35;
    text-align: center;
`;

export const ModalFooter = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282d35;

`;

export const ModalButtonClose = styled.button`
    border: none;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 10px;
    background-color: #ED2C6D;
    padding: 2px 10px;
    cursor: pointer;
`;

import BagIcon from '../../assets/bag-icon.svg'
import GroupIcon from '../../assets/group-icon.svg'
import PersonIcon from '../../assets/person-icon.svg'
import { CardsContainer } from './styles'
import Botao from '../../components/Botao'
import { Titulo } from '../../components/Titulo'
import { BotoesContainer } from '../../components/BotoesContainer'
import { GrupoCard } from '../../components/GrupoCard'

const SelecionarGrupos = () => {
    return (
        <>
            <Titulo titulo="Selecionar grupos por" />

            <CardsContainer>
                
                <GrupoCard icone={BagIcon} texto="Cargo" disabled/>

                <GrupoCard icone={GroupIcon} texto="Setor" link="/novo-agendamento/selecionar-setores" />

                <GrupoCard icone={PersonIcon} texto="Registro" link="/novo-agendamento/selecionar-funcionarios" />

            </CardsContainer>

            <BotoesContainer>   

                <Botao cor="vermelho" texto="Voltar" link="/novo-agendamento" />   
                 
            </BotoesContainer>
        </>
    )
}

export default SelecionarGrupos
import styled from "styled-components";

export const SidebarContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    background-color: #313A46;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
    transition: all 0.3s ease-in-out;
`

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20%;

    img {
    width: 125px;
    height: 35px;
    }
`

export const NavLinks = styled.ul`
    display: flex;
    flex-direction: column;
    height: 67%;

    li {
    position: relative;
    list-style: none;
    padding-left: 20px;
    margin-bottom: 10px;
    }

    li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    }

   

    li img {
    width: 25px;
    height: 25px;
    color: #fff;
    }

    li a h2 {
    margin-left: 10px;
    font-size: 18px;
    color: #fff;
    }

    ul {
        padding-top: 8px;
        padding-left: 8px;
        color: #fff;

        li {
            list-style: none;
            margin-bottom: 0px;
            padding: 4px;

            &:hover {
                background-color: #3c4655;
                border-radius: 5px;
                
            }
            
            h3 {
                font-size: 16px;
                color: #fff;
            }
        }
    }

`

export const TicketsList = styled.div`
    display: flex;
    flex-direction: column;

    padding-left: 40px;
    margin-bottom: 10px;
    list-style: none;
    padding-left: 20px;
    margin-bottom: 10px;
    position: relative;

    color: #fff;

    li {
        list-style: none;
        margin-bottom: 0px;
        padding: 4px;

        &:hover {
            background-color: #3c4655;
            border-radius: 5px;
            
        }

        h3 {
            font-size: 16px;
            color: #fff;
        }
    }
`

export const SidebarFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 15%;
    border-top: #fff 1px solid;
    position: relative;

    h2 {
    font-size: 18px;
    color: #fff;
    }

    img {
    width: 35px;
    height: 35px;
    color: #fff;
    }

`

export const FiltroContainer = styled.div`
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(35px,35px);
    z-index: 1;
    width: 200px;
    /* height: 10%; remove if unnecessary */
    display: ${props => (props.ativo ? 'flex' : 'none')};
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
     /* Adjust padding as needed */
    background: #3c4655; /* Add a background color if needed */
    border-radius: 5px;
    

    li {
    list-style: none;
    padding: 10px 20px;
    
    height: 100%;
    width: 100%;
    
    

    }

    //li hover
    li:hover {
    //background-color brighter than #313A46
    background-color: #323a46;
    border-radius: 5px;
    }
`;
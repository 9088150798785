const initialState = {
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
    id: "",
    user: "",
    empresa: "",
    grupoAcesso: "",
    modulos: {
        moduloPublicacoes: false,
        moduloCandidatos: false,
        moduloTickets: false,
    }
}


const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            const { moduloPublicacoes, moduloCandidatos, moduloTickets } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                id: action.payload.id,
                user: action.payload.usuario,
                empresa: action.payload.empresa,
                grupoAcesso: action.payload.grupoAcesso,
                modulos: action.payload.modulos,
            }
        case 'LOGOUT_SUCCESS':
            return {
                ...state,
                isAuthenticated: false,
                id: "",
                user: {},
                empresa: "",
                grupoAcesso: "",
                modulos: {
                    ...state.modulos,
                    moduloPublicacoes: false,
                    moduloCandidatos: false,
                    moduloTickets: false,
                },
            }
        

        default:
            return state
    }
}

export default authReducer
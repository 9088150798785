

export const setTitulo = (titulo) => ({
    type: 'SET_TITULO',
    payload: titulo
});

export const setData = (data) => ({
    type: 'SET_DATA',
    payload: data
})

export const setHora = (hora) => ({
    type: 'SET_HORA',
    payload: hora
});

export const setDataTimestamp = (dataTimestamp) => ({
    type: 'SET_DATA_TIMESTAMP',
    payload: dataTimestamp
});

export const setMensagem = (mensagem) => ({
    type: 'SET_MENSAGEM',
    payload: mensagem
});

export const setImagemPush = (imagemPush) => ({
    type: 'SET_IMAGEM_PUSH',
    payload: imagemPush
});

export const setImagemInputValue = (imagemInputValue) => ({
    type: 'SET_IMAGEM_INPUT_VALUE',
    payload: imagemInputValue
});

export const setFuncionarios = (funcionarios) => ({
    type: 'SET_FUNCIONARIOS',
    payload: funcionarios
});

export const setSetores = (setores) => ({
    type: 'SET_SETORES',
    payload: setores
});

export const setFuncionariosSelecionados = (funcionariosSelecionados) => ({
    type: 'SET_FUNCIONARIOS_SELECIONADOS',
    payload: funcionariosSelecionados
});

export const setFuncionariosSelecionadosId = (funcionariosSelecionadosId) => ({
    type: 'SET_FUNCIONARIOS_SELECIONADOS_ID',
    payload: funcionariosSelecionadosId
});

export const setSetoresSelecionados = (setoresSelecionados) => ({
    type: 'SET_SETORES_SELECIONADOS',
    payload: setoresSelecionados
});

export const setGrupos = (grupos) => ({
    type: 'SET_GRUPOS',
    payload: grupos
});
import { ModalComponent, ModalHeader, ModalTitle, ModalBody, ModalFooter, ModalButtonClose, ModalMessage, ButtonX } from './styles';


export function handleCloseModal() {
    const modal = document.querySelector('#modal');
    modal.close();
}

export function handleShowModal() {
    const modal = document.querySelector('#modal');
    modal.showModal();
}

export function Modal({titulo, mensagem, botao}) {
    return (
        <ModalComponent id='modal'>
                <ModalHeader>
                    <ModalTitle>{titulo}</ModalTitle>
                    <ButtonX  className='closeButton' onClick={handleCloseModal}>X</ButtonX>
                </ModalHeader>
                <ModalBody>
                    <ModalMessage>{mensagem}</ModalMessage>
                </ModalBody>
                <ModalFooter>
                    <ModalButtonClose className='closeButton' onClick={handleCloseModal}>{botao}</ModalButtonClose>
                </ModalFooter>
        </ModalComponent>
    );
};



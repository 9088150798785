import React, {useState, useEffect} from 'react';
import { Container, FiltroContainer, FiltroTitulo, FiltroOpcoes, FiltroOpcao, AvisoContainer  } from './styles';
import expandIcon from '../../assets/expand-icon.svg';
import { Titulo } from '../../components/Titulo';
import { TabelaCandidatos } from '../../components/TabelaCandidatos';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCandidatos } from '../../api';
import { setCandidatos } from '../../redux/candidatos/candidatosActions';

export const Candidatos = () => {

    const filtros = [
        { id: 1, titulo: 'Candidatos em análise' },
        { id: 2, titulo: 'Candidatos aprovados' },
        { id: 3, titulo: 'Todos os candidatos' }
    ];
    

    const [filtro, setFiltro] = useState(filtros[0]);
    const [filtroAtivo, setFiltroAtivo] = useState(false);
    const [candidatosFiltrados, setCandidatosFiltrados] = useState([]);
    const [aviso, setAviso] = useState(true);

    const dispatch = useDispatch();
    
    const candidatos = useSelector((state) => state.candidatos.candidatos);

    

    const handleClick = () => {
        setFiltroAtivo(!filtroAtivo);
        console.log(candidatos);
    }

    const handleFiltro = (id) => {
        setFiltro(filtros[id-1]);
        setFiltroAtivo(false);
    }

    useEffect(() => {
        let candidatosFiltrados = [];
      
        if (candidatos && candidatos.length > 0) {
          candidatosFiltrados = candidatos.filter((candidato) => {
            if (filtro.id === 1) {
              return (
                candidato.candidato.situacao === 'EM ANALISE' || candidato.candidato.situacao === 'NOVO'
              );
            }
            if (filtro.id === 2) {
              return candidato.candidato.situacao === 'APROVADO';
            }
            if (filtro.id === 3) {
                return true;
            }
            return false;
          });
      
          // sort by ID
          candidatosFiltrados.sort((a, b) => a.id - b.id);
        }
        console.log(candidatosFiltrados);
      
        setCandidatosFiltrados(candidatosFiltrados);
      }
    
    , [filtro, candidatos]);

    useEffect(() => {
        fetchCandidatos().then((candidatos) => {
            dispatch(setCandidatos(candidatos));
        });
    }, [dispatch]);

    return (
        <>

        {aviso ? (
            <AvisoContainer>
                <h1>Aviso</h1>
                <h2>O módulo de candidatos está em desenvolvimento.</h2>
                <h2>Futuramente será possível visualizar os candidatos cadastrados e suas informações.</h2>
                <h2>Todos os candidatos presentes nesta página são fictícios.</h2>
                <h2>Para mais informações, entre em contato com o suporte.</h2>
                <button onClick={() => setAviso(false)}>Entendi</button>
            </AvisoContainer>
        ) : (
        <Container>
            <Titulo titulo='Candidatos' />

            <FiltroContainer>

                <div onClick={() => handleClick()}>

                    <FiltroTitulo>
                        <h2>{filtro.titulo}</h2>
                        <img src={expandIcon} alt='expand-icon' />
                    </FiltroTitulo>

                    {filtroAtivo && (
                        <FiltroOpcoes>
                            {filtros.map((filtro) => (
                                <FiltroOpcao key={filtro.id} onClick={() => handleFiltro(filtro.id)}>
                                    <h2>{filtro.titulo}</h2>
                                </FiltroOpcao>
                            ))}
                        </FiltroOpcoes>
                    )}

                </div>

            </FiltroContainer>

            <TabelaCandidatos candidatos={candidatosFiltrados} />
        </Container>
        )}
        </>
    )
}


import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NovoAgendamento from './pages/NovoAgendamento';
import SelecionarGrupos from './pages/SelecionarGrupos';
import SelecionarSetores from './pages/SelecionarSetores';
import ConfirmarFuncionarios from './pages/ConfirmarFuncionarios';
import SelecionarFuncionarios from './pages/SelecionarFuncionarios';
import { Candidatos } from './pages/Candidatos';
import { Candidato } from './pages/Candidato';
import './App.css';
import { GlobalStyle } from './styles/global';
import { DefaultLayout } from './layouts/DefaultLayout';
import { Login } from './pages/Login';
import { Tickets } from './pages/Tickets';
import { Ticket } from './pages/Ticket';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/configureStore';
import { MudarSenha } from './pages/MudarSenha';
import { logoutSuccess } from './redux/auth/authActions';
import EditarUsuarios from './pages/EditarUsuarios';
import Publicacoes from './pages/Publicacoes';




import NovaHome from './pages/NovaHome';
import { Uploads } from './pages/Uploads';

function App() {


  const ProtectedRoute = ({ element: Element, ...rest }) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const permissions = useSelector((state) => state.auth.grupoAcesso);

    const dispatch = useDispatch();





    if (!permissions || permissions === "") {
      return (
        // alert("Ocorrerram problemas ao carregar as permissões do usuário. Por favor, entre em contato com o suporte."),
        dispatch(logoutSuccess()),
        <Navigate to="/login" />
      );
    }


    if (isAuthenticated) {
      return <Element {...rest} />;
    } else {
      return <Navigate to="/login" />;
    }
  };




  return (
    
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
              path="/"
              element={<ProtectedRoute element={DefaultLayout} />}
            >
          
            <Route exact path="/" element={<NovaHome/>} />
                
              <Route path="/novo-agendamento" >
                
                  <Route index element={<NovoAgendamento />} />
                  <Route path="selecionar-grupos" element={<SelecionarGrupos />} />
                  <Route path="selecionar-setores" element={<SelecionarSetores />} />
                  <Route path="confirmar-funcionarios" element={<ConfirmarFuncionarios />} />
                  <Route path="selecionar-funcionarios" element={<SelecionarFuncionarios />} />
                
              </Route>

              <Route path="/tickets/:id" element={<Tickets />} />
              <Route path="/ticket/:id" element={<Ticket />} />
              <Route path="/candidatos" element={<Candidatos />} />
              <Route path="/candidato/:id" element={<Candidato />} />
              <Route path="/uploads" element={<Uploads />} />
              <Route path="/usuario/mudar-senha" element={<MudarSenha />} />
              <Route path="/usuarios" element={<EditarUsuarios />} />
              
          </Route>
        </Routes>
        
      </div>
      </PersistGate>
      </Provider>

      <GlobalStyle />
    </Router>
    
  );
}

export default App;

import { autenticarUsuario, changePassword } from "../../api"
import Botao from "../../components/Botao"
import { Titulo } from "../../components/Titulo"
import { BotoesContainer } from "./styles"
import { Container, InputContainer } from "./styles"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import bcrypt from 'bcryptjs'
import { checarSenha } from "../../api"

export const MudarSenha = () => {

    const [senhaAtual, setSenhaAtual] = useState('')
    const [novaSenha, setNovaSenha] = useState('')
    const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('')
    const dispatch = useDispatch()

    const usuario = useSelector(state => state.auth.user)
    const usuarioId = useSelector(state => state.auth.id)

    const handleSenhaAtual = (e) => {
        setSenhaAtual(e.target.value)
    }

    const handleNovaSenha = (e) => {
        setNovaSenha(e.target.value)
    }

    const handleConfirmarNovaSenha = (e) => {
        setConfirmarNovaSenha(e.target.value)
    }

    const handleMudarSenha = async (e) => {
        e.preventDefault()

        if (novaSenha !== confirmarNovaSenha) {
            alert('As senhas não coincidem')
            return
        }
        console.log('senha atual', senhaAtual)
        console.log('nova senha', novaSenha)
        console.log('confirmar nova senha', confirmarNovaSenha)
        console.log('usuario', usuario)

        const senhaBcrypt = await bcrypt.hash(senhaAtual, 10)
        console.log('senhaBcrypt', senhaBcrypt)
        const senhaNovaBcrypt = await bcrypt.hash(novaSenha, 10)
        //checa se a senha atual está correta
        const senhaCorretaHash = await checarSenha(usuario)
        console.log('senhaCorreta', senhaCorretaHash)

        const senhaCorreta = await bcrypt.compare(senhaAtual, senhaCorretaHash)
        console.log('senhaCorreta', senhaCorreta)

        if (senhaCorreta === false) {
            alert('Senha atual incorreta')
            return
        }

        //muda a senha
        const mudarSenha = await changePassword(usuarioId, senhaNovaBcrypt)

        alert('Senha alterada com sucesso')
        setSenhaAtual('')
        setNovaSenha('')
        setConfirmarNovaSenha('')
        return
    }
    
  return (
    <>
        <Titulo titulo="Mudar Senha" />

        <Container>
            <InputContainer width="100%">
                <label>Senha atual</label>
                <input type="password" placeholder="Senha atual" onChange={handleSenhaAtual} autocomplete="new-password" />
            </InputContainer>

            <InputContainer width="100%">
                <label>Nova senha</label>
                <input type="password" placeholder="Nova senha" onChange={handleNovaSenha}/>
            </InputContainer>

            <InputContainer width="100%">
                <label>Confirmar nova senha</label>
                <input type="password" placeholder="Confirmar nova senha" onChange={handleConfirmarNovaSenha}/>
            </InputContainer>

            <BotoesContainer>
                <Botao cor="azul" texto="Mudar Senha" onClick={handleMudarSenha} />
            </BotoesContainer>
        </Container>
    </>
  )
}
import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30vw;
    overflow: hidden;
    margin-top: 2rem;
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${props => props.width ? props.width : '100%'};
    margin: 1rem 0;

    label {
        display: flex;
        width: 30vw;
        align-items: start;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        // would like to have the label on the left side of the input
        text-align: left;
    }

    input {
        width: 30vw;
        height: 3rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        padding: 0 1rem;
        font-size: 1.2rem;
        font-weight: 500;
        outline: none;
        transition: 0.2s;
    }
    `
export const BotoesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 30px;
    margin-top: 2.5rem;
    `
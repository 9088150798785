import { styled } from "styled-components";
import { css, keyframes } from "styled-components";

const expandAnimation = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 300px; /* Set the desired expanded height */
    opacity: 1;
  }
`;

const collapseAnimation = keyframes`
  from {
    max-height: 300px; /* Set the desired expanded height */
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
`;

export const TituloContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 2rem;
    width: 100%;
    padding: 0 3rem;
    margin-top: 2rem;

    img {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        cursor: pointer;
    }

    h1 {
        font-size: 2rem;
        font-weight: 400;
        color: #333333;
        padding-left: 1rem;
    }
`;

export const TicketContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
    margin-bottom: 2rem;
    width: 100%;
    padding: 0 3rem;
    gap: 2rem;

`;

export const DetalhesContainer = styled.div`
    display: flex;
    
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
    width: 25vw;
    height: 80vh;
    background: #FFFFFF;
    border-radius: 10px;


`;

export const DetalhesTitulo = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 1rem 0;

    border-bottom: 0.8px solid #000000;
    width: 100%;
    justify-content: space-between;

    h2 {
        font-size: 1rem;
        font-weight: 600;
        color: #333333;
    }
    `;

export const DetalhesTicket = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
width: 100%;

background: #ffffff;

h3 {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333333;
}

p {
  font-size: 0.8rem;
  font-weight: 400;
  color: #333333;
  padding: 0 0.5rem;
}

/* Set the animation based on the expanded state */
${props =>
  props.expanded
    ? css`
        animation: ${expandAnimation} 0.5s ease; /* Use the expand animation */
      `
    : css`
        animation: ${collapseAnimation} 0.5s ease; /* Use the collapse animation */
      `}

/* Set the initial max-height based on the expanded state */
max-height: ${props => (props.expanded ? "300px" : "0")};
overflow: hidden;
`;
export const DetalhesTicketsTitulo = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 0.8rem;

    gap: 0.5rem;


    width: 100%;
    justify-content: space-between;

    h3 {
        font-size: 1rem;
        font-weight: 600;
        color: #333333;
    }

    p {
        font-size: 0.8rem;
        font-weight: 400;
        color: #333333;
    }

    `;

export const DetalhesTicketsData = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0.8rem 0;
    gap: 0.5rem;

    border-bottom: 0.8px solid #000000;
    width: 100%;
    justify-content: space-between;

    h3 {
        font-size: 1rem;
        font-weight: 600;
        color: #333333;
    }

    p {
        font-size: 0.8rem;
        font-weight: 400;
        color: #333333;
    }

    `;

export const DetalhesFuncionario = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0.8rem 0;
    gap: 0.5rem;

    border-bottom: 0.8px solid #000000;
    width: 100%;
    justify-content: space-between;

    h2 {
        font-size: 0.9rem;
        font-weight: 600;
        color: #333333;
    }

    h3 {
        font-size: 0.9rem;
        font-weight: 400;
        color: #333333;
    }

    p {
        font-size: 0.8rem;
        font-weight: 400;
        color: #333333;
        padding: 0 0.5rem;
    }

    ${props =>
  props.expanded
    ? css`
        animation: ${expandAnimation} 0.5s ease; /* Use the expand animation */
      `
    : css`
        animation: ${collapseAnimation} 0.5s ease; /* Use the collapse animation */
      `}

/* Set the initial max-height based on the expanded state */
max-height: ${props => (props.expanded ? "300px" : "0")};
overflow: hidden;

    `;

export const  DetalhesObservacoes = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0.8rem 0;
    gap: 0.5rem;

    border-bottom: 0.8px solid #000000;
    width: 100%;
    justify-content: space-between;

    h2 {
        font-size: 0.9rem;
        font-weight: 600;
        color: #333333;
    }

    select {
        width: 100%;
        height: 2rem;
        border-radius: 5px;
        border: 1px solid #333333;
        padding: 0 0.5rem;
        font-size: 0.8rem;
        font-weight: 400;
        color: #333333;
    }

    textarea {
        width: 100%;
        height: 5rem;
        border-radius: 5px;
        border: 1px solid #333333;
        padding: 0.5rem;
        font-size: 0.8rem;
        font-weight: 400;
        color: #333333;
        resize: none;
    }

    ${props =>
  props.expanded
    ? css`
        animation: ${expandAnimation} 0.5s ease; /* Use the expand animation */
      `
    : css`
        animation: ${collapseAnimation} 0.5s ease; /* Use the collapse animation */
      `}

/* Set the initial max-height based on the expanded state */
max-height: ${props => (props.expanded ? "300px" : "0")};
overflow: hidden;

    `;

export const TicketChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 80vh;

    
    `;

export const BalaoMensagem = styled.div`
  background-color: #ED2C6D;
  color: #333;
  padding: 10px;
  border-radius: 16px 16px 0px 16px;
  margin-bottom: 10px;
  align-self: flex-end;
  word-break: break-word;
  max-width: 80%;

    p {
        color: #fff
    }
    
`;

export const BalaoInteracao = styled.div`
  background-color: #F5F6FA;
  color: #fff;
  padding: 10px;
  border-radius: 16px 16px 16px 0px;
  margin-bottom: 10px;
  align-self: flex-start;
  word-break: break-word;
  max-width: 80%;
`;

export const Chat = styled.div`
    flex-direction: column;
    display: flex;
    padding: 10px 20px;
    gap: 16px;
    width: 100%;
    height: 100%;

    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    overflow-y: scroll;
    `;

export const ChatTitulo = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0.8rem 0;
    border-bottom: 0.8px solid #000000;
    width: 100%;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 20px;

    h2 {
        font-size: 1rem;
        font-weight: 600;
        color: #333333;
    }

    `;


export const RespostaContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const RespostaInput = styled.input`
  flex: 1;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;

  ${props => props.disabled && css`
    opacity: 0.5;
    cursor: default;
    `}
`;

export const EnviarBotao = styled.button`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #ed2c6d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  ${props => props.disabled && css`
    opacity: 0.5;
    cursor: default;
    `}
`;

export const EncerrarBotao = styled.button`
    margin-left: 10px;
    padding: 8px 16px;
    background-color: #D63636;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    `;

export const BoxResposta = styled.div`
    display: flex;
    align-items: center;
    border: none;
    border-radius: 0px 0px 4px 4px;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    `;

export const Alerta = styled.h1`
    color: red;
    font-size: 0.8rem;
    font-weight: 600;
    `

export const Modal = styled.dialog`
    width: 400px;
    height: 300px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 30px;
    left: 0;
    right: 0;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 0px 10px #777777;
    animation: fade-in 0.15s ease-in;

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export const ModalHeader = styled.div`
    width: 100%;
    height: 60px;
    background-color: #282d35;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ModalTitle = styled.h1`
    color: #ffffff;
    font-size: 24px;
    margin-left: 20px;
`;

export const ButtonX = styled.button`
    height: 100%;
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 30px;
    margin-right: 20px;
    cursor: pointer;
`;

export const ModalBody = styled.div`
    width: 100%;
    height: 180px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalMessage = styled.p`
    font-size: 20px;
    color: #282d35;
    text-align: center;
`;

export const ModalFooter = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282d35;

`;

export const ModalButtonClose = styled.button`
    border: none;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 10px;
    background-color: #ED2C6D;
    padding: 2px 10px;
    cursor: pointer;
`;
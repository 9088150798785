import { styled } from "styled-components";

export const TituloContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20vh;
    padding-top: 5%;

    h1 {
    font-size: 36px;
    color: #313A46;
    }
`